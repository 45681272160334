import api from '@/api'

const state = {
  userData: {
    id: null
  },
  isUnauthorized: false,
  isExpired: false,
  userEvent: {
    name: null
  }
}
const actions = {
  getCurrentUser: ({ commit }) => {
    const data = {
      id: localStorage.getItem('user_id'),
      name: localStorage.getItem('user_name'),
      token: localStorage.getItem('access_token'),
      role_id: localStorage.getItem('user_roles')
    }
    commit('setUserData', data)
  },
  getUserEvent: ({ commit }) => {
    const data = localStorage.getItem('user_event')
    if (data) commit('setUserEvent', JSON.parse(data))
  },
  removeUserData: ({ commit }) => {
    commit('setUserData', { id: null })
    localStorage.removeItem('user_id')
    localStorage.removeItem('user_name')
    localStorage.removeItem('access_token')
    localStorage.removeItem('user_roles')
    localStorage.removeItem('student_id')
    localStorage.removeItem('photo')
    localStorage.removeItem('customer_employee')
  },
  removeUserEvent: ({ commit }) => {
    commit('setUserEvent', { name: null })
    localStorage.removeItem('user_event')
  },
  loginStudent({ commit }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.auth.loginStudent(payloads).then(
        (response) => {
          commit('setUserData', response.data.data)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  loginPrakerja({ commit }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.auth.loginPrakerja(payloads).then(
        (response) => {
          commit('setUserData', response.data.data)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  loginMitra({ commit }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.auth.loginMitra(payloads).then(
        (response) => {
          commit('setUserData', response.data.data)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  logoutPrakerja({ dispatch }) {
    return new Promise((resolve, reject) => {
      api.auth.logoutPrakerja().then(
        (response) => {
          dispatch('removeUserData')
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  registerMitra({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.auth.registerMitra(payloads).then(
        (response) => resolve(response.data),
        (error) => reject(error)
      )
    })
  },
  registerEvent({ _ }, { formToken, requestBody }) {
    return new Promise((resolve, reject) => {
      api.auth.registerEvent(formToken, requestBody).then(
        (response) => resolve(response.data),
        (error) => reject(error)
      )
    })
  },
  registerStudent({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.auth.registerStudent(payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  registerPrakerja({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.auth.registerPrakerja(payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  registerOpenDay({ _ }, { openDayId, formToken, params, requestBody }) {
    return new Promise((resolve, reject) => {
      api.auth.registerOpenDay(openDayId, formToken, params, requestBody).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  resetPasswordCRM({ _ }, { email }) {
    return new Promise((resolve, reject) => {
      api.auth.resetPasswordCRM(email).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  changePasswordCRM({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.auth.changePasswordCRM(payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  changePasswordPrakerja({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.auth.changePasswordPrakerja(payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  resetPasswordPrakerja({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.auth.resetPasswordPrakerja(payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  loginEvent({ commit }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.auth.loginEvent(payloads).then(
        (response) => {
          commit('setUserEvent', response.data.data)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  resetPasswordEvent({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.auth.resetPasswordEvent(payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  showUnauthorizedModal({ commit }) {
    commit('setUnauthorized', true)
  },
  hideUnauthorizedModal({ commit }) {
    commit('setUnauthorized', false)
  },
  showTokenExpiredModal({ commit }) {
    commit('setExpired', true)
  },
  hideTokenExpiredModal({ commit }) {
    commit('setExpired', false)
  }
}
const mutations = {
  setUserData(state, value) {
    state.userData = value
  },
  setUnauthorized(state, value) {
    state.isUnauthorized = value
  },
  setExpired(state, value) {
    state.isExpired = value
  },
  setUserEvent(state, value) {
    state.userEvent = value
    localStorage.setItem('user_event', JSON.stringify(value))
  }
}
const getters = {
  userData: (state) => state.userData,
  isLoggedIn: (state) => (state && state.userData ? state.userData.id !== null : false),
  isUnauthorized: (state) => state.isUnauthorized,
  isExpired: (state) => state.isExpired,
  userEvent: (state) => state.userEvent,
  isUserEventLoggedIn: (state) => (state && state.userEvent ? state.userEvent.name !== null : false)
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
