import { api } from '@/constant'
import { getAuthHeader, serializeQueryParams } from '@/utils'
import apiV1 from '@/service/api-v1'
import apiV2 from '@/service/api-v2'
import apiUrlV4 from '@/service/api-url-v4'
import baseApi from '@/service/api-general'
const program = {
  getProgramBySlug: (slug) => {
    const url = api.program.programBySlug(slug)
    return baseApi.get(url)
  },
  getProgramByWebSlug: (slug, params) => {
    const url = api.program.programByWebSlug(slug, serializeQueryParams(params))
    return baseApi.get(url)
  },
  getVariantDetail: (variantId, params) => {
    const url = api.program.variantDetail(variantId) + serializeQueryParams(params)
    return baseApi.get(url)
  },
  downloadSyllabus: (payloads) => {
    const url = api.program.downloadSyllabus
    return apiV2.post(url, payloads, { headers: getAuthHeader() })
  },
  getLmsClasses: (coreClassId, params) => {
    const url = api.program.webClass + `/${coreClassId}/lms-classes` + serializeQueryParams(params)
    return baseApi.get(url)
  },
  getFormToken: () => {
    const url = api.program.formToken
    return baseApi.get(url)
  },
  attendOpenDay: (eventId, payloads) => {
    const url = api.program.attendOpenDay(eventId)
    return baseApi.post(url, payloads)
  },
  enroll: (payloads) => {
    const url = api.program.enroll
    return apiV2.post(url, payloads, { headers: getAuthHeader() })
  },
  enrollBookSession: (payloads, formToken) => {
    const url = `${api.program.newEnrollV2}/lms-class/private-consultation`
    return apiV2.post(url, payloads, { headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token'), 'Access-Control-Allow-Origin': '*', 'x-g2-form-token': formToken } })
  },
  enrollV2: (payloads) => {
    const url = api.program.enrollV2
    return baseApi.post(url, payloads)
  },
  newEnroll: (payloads, formToken) => {
    const url = api.program.newEnroll
    return baseApi.post(url, payloads, { headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token'), 'Access-Control-Allow-Origin': '*', 'x-g2-form-token': formToken } })
  },
  newEnrollV2: (payloads, formToken) => {
    const url = api.program.newEnrollV2
    return apiV2.post(url, payloads, { headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token'), 'Access-Control-Allow-Origin': '*', 'x-g2-form-token': formToken } })
  },
  getSchedulePrice: (params) => {
    const url = api.program.schedulePrice + serializeQueryParams(params)
    return baseApi.get(url)
  },
  getEventList: (params) => {
    const url = api.program.eventList + serializeQueryParams(params)
    return apiV1.get(url)
  },
  getProgramCategoryList: (params) => {
    const url = api.program.programCategoryList + serializeQueryParams(params)
    return apiUrlV4.get(url)
  },
  getProgramByCategory: (params) => {
    const url = api.program.storeClasses + serializeQueryParams(params)
    return apiUrlV4.get(url)
  }
}
export default program
