import { api } from '@/constant'
import { getAuthHeader, serializeQueryParams, getAuthAndMultipartHeader } from '@/utils'
import apiV2 from '@/service/api-v2'
import baseApi from '@/service/api-general'
import apiUrlHR from '@/service/api-hr'
import apiUrlHRNoOffset from '@/service/api-hr-no_offset'

// https://dev-api-v4.g2academy.co/new/hr/leave/{id}/cancel

const student = {
  createRedeemRegister: (payloads) => {
    const url = api.student.redeemRegister
    return baseApi.post(url, payloads, {
      headers: getAuthHeader()
    })
  },
  getLeaveRequestList: (params) => {
    const url = `${api.student.leaveRequest}${serializeQueryParams(params)}`
    return apiUrlHR.get(url, { headers: { Authorization: 'Bearer' + ' ' + localStorage.getItem('access_token') } })
  },
  createNewLeaveRequest: (payloads) => {
    const url = api.student.leaveRequest
    return apiUrlHRNoOffset.post(url, payloads, { headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token') } })
  },
  recallLeaveRequest: (leaveRequestId, payloads) => {
    const url = `${api.student.leaveRequest}/${leaveRequestId}/cancel`
    return apiUrlHR.put(url, payloads, { headers: { Authorization: 'Bearer' + ' ' + localStorage.getItem('access_token') } })
  },
  getLeaveBalanceOptions: (customerId) => {
    const url = `${api.student.leaveBalance}/lookup/${customerId}`
    return apiUrlHR.get(url, { headers: { Authorization: 'Bearer' + ' ' + localStorage.getItem('access_token') } })
  },
  getMyClass: (params) => {
    const url = api.student.getMyClass(serializeQueryParams(params))
    return apiV2.get(url, {
      headers: getAuthHeader()
    })
  },
  getAdministration(registrationId) {
    let url = api.student.administration
    if (registrationId) {
      url = url + `/${registrationId}`
    }
    return apiV2.get(url, {
      headers: getAuthHeader()
    })
  },
  uploadRegistrationFile(registrationId, registrationDocumentId, payloads) {
    const url = `${api.student.administration}/${registrationId}/documents/${registrationDocumentId}`
    return apiV2.post(url, payloads, {
      headers: getAuthHeader()
    })
  },
  getClassById: (classId) => {
    const url = api.student.getClassById(classId)
    return apiV2.get(url, {
      headers: getAuthHeader()
    })
  },
  getMessageList: (classId, params) => {
    const url = api.student.getMessageList(classId, serializeQueryParams(params))
    return baseApi.get(url, {
      headers: getAuthHeader()
    })
  },
  replyMessage: (messageTopicId, payloads) => {
    const url = api.student.replyMessage(messageTopicId)
    return baseApi.post(url, payloads, {
      headers: getAuthHeader()
    })
  },
  getScheduleDashboard: (classId, params) => {
    const url = api.student.getScheduleDashboard(classId, serializeQueryParams(params))
    return apiV2.get(url, {
      headers: getAuthHeader()
    })
  },
  getSchedule: (params, formToken) => {
    const url = api.student.getSchedule(serializeQueryParams(params))
    return baseApi.get(url, { headers: { Authorization: 'Bearer ' + localStorage.getItem('access_token'), 'Access-Control-Allow-Origin': '*', 'x-g2-form-token': formToken } })
  },
  getScheduleOnward: (classId) => {
    const url = api.student.getScheduleOnward(classId)
    return apiV2.get(url, {
      headers: getAuthHeader()
    })
  },
  createAttendance: (payloads) => {
    const url = api.student.createAttendance
    return apiV2.post(url, payloads, {
      headers: getAuthHeader()
    })
  },
  uploadImage: (data) => {
    const url = api.student.image
    return baseApi.post(url, data, {
      headers: getAuthAndMultipartHeader()
    })
  },
  uploadFile: (data) => {
    const url = api.student.file
    return baseApi.post(url, data, {
      headers: getAuthAndMultipartHeader()
    })
  },
  getStudentDetail: (studentId) => {
    const url = api.student.getStudentDetail(studentId)
    return baseApi.get(url, {
      headers: getAuthHeader()
    })
  },
  updateStudent: (studentId, payloads) => {
    const url = api.student.updateStudent(studentId)
    return baseApi.put(url, payloads, {
      headers: getAuthHeader()
    })
  },
  updateEmailStudent: (studentId, payloads) => {
    const url = api.student.updateEmailStudent(studentId)
    return baseApi.put(url, payloads, {
      headers: getAuthHeader()
    })
  },
  doTheTask: (payloads) => {
    const url = api.student.doTheTask
    return baseApi.post(url, payloads, {
      headers: getAuthHeader()
    })
  },
  doTheExam: (payloads) => {
    const url = api.student.doTheExam
    return baseApi.post(url, payloads, {
      headers: getAuthHeader()
    })
  },
  updateTheTask: (submissionId, payloads) => {
    const url = api.student.updateTheTask(submissionId)
    return baseApi.put(url, payloads, {
      headers: getAuthHeader()
    })
  },
  updateTheExam: (submissionId, payloads) => {
    const url = api.student.updateTheExam(submissionId)
    return baseApi.put(url, payloads, {
      headers: getAuthHeader()
    })
  }
}
export default student
