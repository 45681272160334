import { api } from '@/constant'
import { getAuthHeaderJWT, getAuthAndMultipartHeaderJWT, serializeQueryParams } from '@/utils'
import { apiPrakerja, apiPrakerjaV2 } from '@/service/api-prakerja'
import baseApi from '@/service/api-general'
import apiV3 from '@/service/api-v3'

const prakerja = {
  getPrakerjaContent: () => {
    const url = api.prakerja.content
    return baseApi.get(url)
  },
  getUserProfile: () => {
    const url = api.prakerja.profile
    return apiPrakerjaV2.get(url, { headers: getAuthHeaderJWT() })
  },
  redeemCode: (payloads) => {
    const url = api.prakerja.redeem
    return apiPrakerja.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  myClass: (params) => {
    const url = api.prakerja.myClass + serializeQueryParams(params)
    return apiPrakerjaV2.get(url, { headers: getAuthHeaderJWT() })
  },
  myClassDetail: (classId) => {
    const url = api.prakerja.myClassDetail(classId)
    return apiPrakerjaV2.get(url, { headers: getAuthHeaderJWT() })
  },
  myClassAdditionalInfo: (classId) => {
    const url = api.prakerja.myClassAdditionalInfo(classId)
    return apiV3.get(url, { headers: getAuthHeaderJWT() })
  },
  nonWebinarClassDetail: (classId) => {
    const url = api.prakerja.myClassDetail(classId)
    return apiV3.get(url, { headers: getAuthHeaderJWT() })
  },
  needRedemptionCode: (classId, activityItemId) => {
    const url = api.prakerja.needRedemptionCode(classId, activityItemId)
    return apiV3.get(url, { headers: getAuthHeaderJWT() })
  },
  updateVideoStatus: (studentLmsClassId, activityItemId, payloads) => {
    const url = api.prakerja.videoStatus(studentLmsClassId, activityItemId)
    return apiV3.post(url, payloads, { headers: getAuthHeaderJWT() })
      .catch(function (error) {
        if (error.response) {
          return error.response
        }
      })
  },
  updateDocumentStatus: (studentLmsClassId, activityItemId, payloads) => {
    const url = api.prakerja.documentStatus(studentLmsClassId, activityItemId)
    return apiV3.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  updateLinkStatus: (studentLmsClassId, activityItemId, payloads) => {
    const url = api.prakerja.linkStatus(studentLmsClassId, activityItemId)
    return apiV3.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  updateRecurringStatus: (studentLmsClassId, activityItemId, payloads) => {
    const url = api.prakerja.recurringStatus(studentLmsClassId, activityItemId)
    return apiV3.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  updateUserProfile: (payloads) => {
    const url = api.prakerja.profile
    return apiPrakerjaV2.put(url, payloads, { headers: getAuthHeaderJWT() })
  },
  updateExamDataNonWebinar: (studentLmsClassId, activityItemId, payloads) => {
    const url = api.prakerja.examDataNonWebinar(studentLmsClassId, activityItemId) + '/single-answer'
    return apiV3.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  sendDropboxQuestion: (id, payloads) => {
    const url = api.prakerja.dropboxQuestion(id)
    return apiPrakerjaV2.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  absensiPrakerja: (id, payloads) => {
    const url = api.prakerja.absensi(id)
    return apiPrakerjaV2.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  absensiOffline: (studentLmsClassId, activityItemId, payloads) => {
    const url = api.prakerja.absensiOffline(studentLmsClassId, activityItemId)
    return apiV3.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  absensiWebinar: (studentLmsClassId, activityItemId, payloads) => {
    const url = api.prakerja.absensiWebinar(studentLmsClassId, activityItemId)
    return apiV3.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  getExamData: (classId, examType) => {
    const url = api.prakerja.examData(classId, examType)
    return apiPrakerjaV2.get(url, { headers: getAuthHeaderJWT() })
  },
  getExamDataNonWebinar: (studentLmsClassId, activityItemId) => {
    const url = api.prakerja.examDataNonWebinar(studentLmsClassId, activityItemId)
    return apiV3.put(url, {}, { headers: getAuthHeaderJWT() })
  },
  getExamDataReference: (classId, examType) => {
    const url = api.prakerja.examDataReference(classId, examType)
    return apiPrakerjaV2.get(url, { headers: getAuthHeaderJWT() })
  },
  getExamDataNonWebinarReference: (studentLmsClassId, activityItemId) => {
    const url = api.prakerja.examDataNonWebinarReference(studentLmsClassId, activityItemId)
    return apiV3.get(url, { headers: getAuthHeaderJWT() })
  },
  submitExam: (classId, examType, payloads) => {
    const url = api.prakerja.examData(classId, examType)
    return apiPrakerjaV2.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  submitExamNonWebinar: (studentLmsClassId, activityItemId, payloads) => {
    const url = api.prakerja.examDataNonWebinar(studentLmsClassId, activityItemId)
    return apiV3.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  uploadTugas: (classId, payloads) => {
    const url = api.prakerja.uploadTugas(classId)
    return apiPrakerjaV2.post(url, payloads, { headers: getAuthAndMultipartHeaderJWT() })
  },
  uploadTugasNonWebinar: (studentLmsClassId, activityItemId, payloads) => {
    const url = api.prakerja.uploadTugasNonWebinar(studentLmsClassId, activityItemId)
    return apiV3.post(url, payloads, { headers: getAuthAndMultipartHeaderJWT() })
  },
  getMyReferral: () => {
    const url = api.prakerja.myReferral
    return apiPrakerja.get(url, { headers: getAuthHeaderJWT() })
  },
  getListMyReferral: () => {
    const url = api.prakerja.myReferralList
    return apiPrakerja.get(url, { headers: getAuthHeaderJWT() })
  }
}
export default prakerja
