import api from '@/api'

const state = {
  homeContent: {}
}
const actions = {
  getHomeContent({ commit }) {
    return new Promise((resolve, reject) => {
      api.home.getHomeContent().then(
        (response) => {
          commit('setHomeContent', response.data.data)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getPopUpContent({ _ }) {
    return new Promise((resolve, reject) => {
      api.home.getPopUpContent().then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getProgramContent({ _ }, { programName, params }) {
    return new Promise((resolve, reject) => {
      api.home.getProgramContent(programName, params).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
const mutations = {
  setHomeContent(state, value) {
    state.homeContent = value
  }
}
const getters = {
  homeContent: (state) => state.homeContent
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
