import { api } from '@/constant'
import apiUrlHR from '@/service/api-hr'

const attendance = {
  postAttend: (payloads) => {
    const url = api.attend.attendance
    return apiUrlHR.post(url, payloads, {
      headers: { Authorization: 'Bearer ' }
    })
  }
}
export default attendance
