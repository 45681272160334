import { api } from '@/constant'
import { getAuthHeader, getAuthHeaderJWT, getOriginHeader, serializeQueryParams } from '@/utils'
import { apiPrakerja, apiPrakerjaV2 } from '@/service/api-prakerja'
import apiV2 from '@/service/api-v2'
import baseApi from '@/service/api-general'

const auth = {
  loginStudent: (payloads) => {
    const url = api.auth.loginStudent
    return apiV2.post(url, payloads)
  },
  loginPrakerja: (payloads) => {
    const url = api.auth.loginPrakerja
    return apiPrakerja.post(url, payloads)
  },
  loginMitra: (payloads) => {
    const url = api.auth.loginMitra
    return baseApi.post(url, payloads, { headers: getOriginHeader() })
  },
  logoutPrakerja: () => {
    const url = api.auth.logoutPrakerja
    return apiPrakerja.post(url, {}, { headers: getAuthHeaderJWT() })
  },
  resetPasswordCRM: (email) => {
    const url = api.auth.resetPasswordCRM
    return baseApi.put(`${url}?email=${email}`)
  },
  resetPasswordPrakerja: (payloads) => {
    const url = api.auth.resetPasswordPrakerja
    return apiPrakerja.post(url, payloads)
  },
  registerMitra: (payloads) => {
    const url = api.auth.registerMitra
    return baseApi.post(url, payloads, { headers: getOriginHeader() })
  },
  registerEvent: (formToken, requestBody) => {
    let headersApi = {}
    if (requestBody.email) {
      headersApi = {
        'x-g2-form-token': formToken
      }
    } else {
      headersApi = {
        'x-g2-form-token': formToken,
        Authorization: 'Bearer ' + localStorage.getItem('access_token'),
        'Access-Control-Allow-Origin': '*'
      }
    }
    const url = api.auth.registerEvent
    return apiV2.post(url, requestBody, {
      headers: headersApi
    })
  },
  registerStudent: (payloads) => {
    const url = api.auth.registerStudent
    return apiV2.post(url, payloads)
  },
  registerPrakerja: (payloads) => {
    const url = api.auth.registerPrakerja
    return apiPrakerjaV2.post(url, payloads)
  },
  registerOpenDay: (openDayId, formToken, params, requestBody) => {
    const url = api.auth.registerOpenDay(openDayId) + serializeQueryParams(params)
    return baseApi.post(url, requestBody, {
      headers: { 'x-g2-form-token': formToken }
    })
  },
  changePasswordCRM: (payloads) => {
    const url = api.auth.changePasswordCRM
    return baseApi.post(url, payloads, {
      headers: getAuthHeader()
    })
  },
  changePasswordPrakerja: (payloads) => {
    const url = api.auth.changePasswordPrakerja
    return apiPrakerja.post(url, payloads, { headers: getAuthHeaderJWT() })
  },
  loginEvent: (payloads) => {
    const url = api.auth.loginEvent
    return baseApi.post(url, payloads)
  },
  resetPasswordEvent: (payloads) => {
    const url = api.auth.resetPasswordEvent
    return baseApi.post(url, payloads)
  }
}
export default auth
