import axios from 'axios'
import store from '@/store'
const apiPrakerja = axios.create({
  baseURL: process.env.VUE_APP_BASE_PATH_PRAKERJA,
  headers: {
    common: {
      'X-Reference': 'lms-student',
      'x-g2-timeZone-offset': new Date().getTimezoneOffset()
    }
  }
})

const apiPrakerjaV2 = axios.create({
  baseURL: process.env.VUE_APP_BASE_PATH_PRAKERJAV2
})

const responseHandler = (response) => {
  if (response.status === 401) {
    store.dispatch('auth/showUnauthorizedModal')
  }
  return response
}

const errorHandler = (error) => {
  if (error.response.status === 401) {
    store.dispatch('auth/showUnauthorizedModal')
  }
  return Promise.reject(error)
}

apiPrakerja.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
)

apiPrakerjaV2.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
)

export { apiPrakerja, apiPrakerjaV2 }
