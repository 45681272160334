import api from '@/api'

const state = {
  scholarships: []
}
const actions = {
  getScholarship({ commit }) {
    return new Promise((resolve, reject) => {
      api.scholarship.getScholarship().then(
        (response) => {
          commit('setScholarship', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  postScholarship({ _ }, { payload }) {
    return new Promise((resolve, reject) => {
      api.scholarship.postScholarship(payload).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
const mutations = {
  setScholarship(state, value) {
    state.scholarships = value
  }
}
const getters = {
  scholarships: (state) => state.scholarships
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
