import api from '@/api'
const state = {}
const actions = {
  contactUs({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.contact.contactUs(payloads).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  contactUsWithCategory({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.contact.contactUsWithCategory(payloads).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
const mutations = {}
const getters = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
