const isaFaq = [
  {
    id: '1',
    text: 'Is there a minimum and maximum age requirement?',
    text_id: 'Apakah ada minimum dan maksimum persyaratan usia?',
    active: false,
    submenu: {
      message: 'No, but at least you must have a D3 degree to use the ISA method.',
      message_id: 'Tidak, namun setidaknya kamu harus memiliki ijazah D3 untuk menggunakan metode ISA.'
    }
  },
  {
    id: '2',
    text: 'What happens if I dont complete the Bootcamp?',
    text_id: 'Apa yang terjadi jika saya tidak menyelesaikan Bootcamp?',
    active: false,
    submenu: {
      message: 'If you fail to complete the Bootcamp, your ISA method will be activated according to the stage where you stopped.',
      message_id: 'Jika kamu tidak berhasil menyelesaikan Bootcamp, maka metode ISA kamu akan aktif sesuai dengan Stage dimana kamu terhenti.'
    }
  },
  {
    id: '3',
    text: 'Do I get a job guarantee after completing the Bootcamp?',
    text_id: 'Apakah saya mendapatkan jaminan kerja setelah menyelesaikan Bootcamp?',
    active: false,
    submenu: {
      message: 'No, but you will be assisted in the job search process through the Career Track system.',
      message_id: 'Tidak, namun kamu akan dibantu proses pencarian kerja melalui sistem Career Track.'
    }
  },
  {
    id: '4',
    text: 'What is Career Track?',
    text_id: 'Apa yang dimaksud dengan Career Track?',
    active: false,
    submenu: {
      message: 'Career Track is a career development service where you will be helped to map job fields according to your expertise, including recommending companies that are currently seeking digital talents.',
      message_id: 'Career Track merupakan layanan pengembangan karir di mana kamu akan dibantu memetakan bidang pekerjaan sesuai dengan keahlianmu sampai dengan merekomendasikan perusahaan-perusahaan yang sedang mencari talenta digital.'
    }
  },
  {
    id: '5',
    text: 'When do I start paying installment fees for the program?',
    text_id: 'Kapan saya mulai membayar cicilan biaya program?',
    active: false,
    submenu: {
      message: 'After you get a job and have income.',
      message_id: 'Setelah kamu mendapatkan pekerjaan dan memiliki penghasilan.'
    }
  },
  {
    id: '6',
    text: 'What happens if I dont have a job?',
    text_id: 'Apa yang terjadi jika saya tidak memiliki pekerjaan?',
    active: false,
    submenu: {
      message: 'If you are currently unemployed, you are not obligated to pay program fees until you have a job again.',
      message_id: 'Apabila kamu sedang tidak bekerja, maka kamu tidak berkewajiban untuk membayar cicilan biaya program sampai kamu memiliki pekerjaan kembali.'
    }
  }
]
export default isaFaq
