const suggestions = [
  {
    index: 1,
    value: [
      {
        title: 'Launch a brand new start!',
        body: 'Upgrade your skills and fast track your career with G2Academy’s Full-stack Bootcamp program.',
        image: 'Rocket',
        cta: 'Take Now'
      },
      {
        title: 'Test your logical thinking skills!',
        body: 'Assess your logic and critical thinking with LoOPs before joining our Full-stack Bootcamp program.',
        image: 'Logic',
        cta: 'Check out LoOPs',
        url: '/class/regular/loops'
      },
      {
        title: 'Don’t have an IT background?',
        body: 'Take the Pramp short course to gain a basic understanding of our core modules.',
        image: 'Programming',
        cta: 'Learn the Basics',
        url: '/class/regular/pramp'
      }
    ]
  },
  {
    index: 2,
    value: [
      {
        title: 'Try out LoOPs now!',
        body: 'Assess your logic and critical thinking with LoOPs before joining our Full-stack Bootcamp program.',
        image: 'Logic',
        cta: 'Enroll Today'
      },
      {
        title: 'Learn more about our Full-stack Bootcamp!',
        body: 'Find out how to upgrade your skills and career with G2Academy’s Full-stack Bootcamp program. ',
        image: 'Rocket',
        cta: 'View All Programs',
        url: '/programs#full-stack-bootcamp'
      },
      {
        title: 'Don’t have an IT background?',
        body: 'Take the Pramp short course to gain a basic understanding of our core modules.',
        image: 'Programming',
        cta: 'Learn the Basics',
        url: '/class/regular/pramp'
      }
    ]
  },
  {
    index: 3,
    value: [
      {
        title: 'Kickstart your IT journey!',
        body: 'Take the Pramp short course to gain a basic understanding of our core modules.',
        image: 'Programming',
        cta: 'Enroll Today'
      },
      {
        title: 'Learn more about our Full-stack Bootcamp!',
        body: 'Find out how to upgrade your skills and career with G2Academy’s Full-stack Bootcamp program. ',
        image: 'Rocket',
        cta: 'View All Programs',
        url: '/programs#full-stack-bootcamp'
      },
      {
        title: 'Try out LoOPs now!',
        body: 'Assess your logic and critical thinking with LoOPs before joining our Full-stack Bootcamp program.',
        image: 'Logic',
        cta: 'Check out LoOPs',
        url: '/class/regular/loops'
      }
    ]
  }
]
export default suggestions
