export const headerMenu = {
  bar: [
    {
      text: '+62 857-7547-5917',
      icon: {
        component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Whatsapp'),
        height: '13',
        width: '13',
        color: '#fff'
      }
    },
    {
      text: 'Wisma Barito Pacific, Slipi, Jakarta Barat',
      icon: {
        component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Location'),
        height: '13',
        width: '13',
        color: 'white'
      }
    },
    {
      text: 'Mon - Sat, 09:00 - 18:00',
      icon: {
        component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Clock'),
        height: '13',
        width: '13',
        color: '#fff'
      }
    }
  ],
  navigation: [
    {
      id: 'program',
      text: 'Programs',
      translation: true,
      active: false,
      icon: {
        component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Program'),
        height: '20',
        width: '20',
        color: '#4D4D4D'
      },
      submenu: {
        title: 'Program',
        description: 'Consectetur adipiscing elit. Cras vel imperdiet magna. Morbi sodales diam lacinia, venenatis risus eu, molestie tortor. Suspendisse iaculis malesuada.',
        menu: [
          {
            id: 'bootcamp',
            title: 'Bootcamp',
            active: false,
            items: [
              {
                text: 'Web & Mobile Development',
                url: '/class/bootcamp/fullstack-web'
              },
              {
                text: 'Data Expert',
                url: '/class/bootcamp/fullstack-data'
              },
              {
                text: 'JavaScript',
                url: '/class/bootcamp/javascript'
              },
              {
                text: 'Java',
                url: '/class/bootcamp/java'
              },
              {
                text: 'Data Science',
                url: '/class/bootcamp/data-science'
              },
              {
                text: 'Data Engineering',
                url: '/class/bootcamp/data-engineering'
              }
            ]
          },
          {
            id: 'short-course',
            title: 'Short Course',
            active: false,
            items: [
              {
                text: 'All Short Course',
                url: '/short-course'
              }
            ]
          },
          {
            id: 'scholarship',
            title: 'Special Programs',
            active: false,
            items: [
              {
                text: 'IndoDigi',
                url: '/indo-digi'
              },
              {
                text: 'Tech Champ Scholarship',
                url: '/tech-champ-scholarship'
              }
            ]
          },
          {
            title: '',
            items: [
              {
                text: 'Explore Programs',
                url: '/programs',
                arrow: true
              }
            ]
          }
        ]
      }
    },
    {
      id: 'corporate',
      text: 'Corporate',
      translation: true,
      active: false,
      icon: {
        component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Partnership'),
        height: '20',
        width: '20',
        color: '#4D4D4D'
      },
      submenu: {
        title: 'Corporate',
        description: 'Consectetur adipiscing elit. Cras vel imperdiet magna. Morbi sodales diam lacinia, venenatis risus eu, molestie tortor. Suspendisse iaculis malesuada.',
        menu: [
          {
            items: [
              {
                text: 'Corporate Training',
                url: '/corporate-training'
              },
              {
                text: 'Talent Pipeline',
                url: '/talent-pipeline'
              },
              {
                text: 'Project',
                url: '/g2lab'
              }
            ]
          }
        ]
      }
    },
    {
      id: 'partnership',
      text: 'Alliance',
      translation: true,
      active: false,
      icon: {
        component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Partnership'),
        height: '20',
        width: '20',
        color: '#4D4D4D'
      },
      submenu: {
        title: 'Alliance',
        description: 'Consectetur adipiscing elit. Cras vel imperdiet magna. Morbi sodales diam lacinia, venenatis risus eu, molestie tortor. Suspendisse iaculis malesuada.',
        menu: [
          {
            items: [
              {
                text: 'Government',
                url: '/government'
              },
              {
                text: 'Education Institutes',
                url: '/education-institutes'
              }
            ]
          }
        ]
      }
    },
    {
      id: 'prakerja',
      text: 'Prakerja',
      translation: true,
      active: false,
      icon: {
        component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Prakerja'),
        height: '20',
        width: '20',
        color: '#4D4D4D'
      },
      url: '/prakerja'
    },
    {
      id: 'about',
      text: 'About',
      translation: true,
      active: false,
      icon: {
        component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/About'),
        height: '20',
        width: '20',
        color: '#4D4D4D'
      },
      submenu: {
        title: 'ABOUT',
        description: 'Consectetur adipiscing elit. Cras vel imperdiet magna. Morbi sodales diam lacinia, venenatis risus eu, molestie tortor. Suspendisse iaculis malesuada.',
        menu: [
          {
            items: [
              {
                text: 'Our Stories',
                url: '/about'
              },
              {
                text: 'G2Lab',
                url: '/g2lab'
              },
              {
                text: 'Products',
                url: '/products'
              },
              {
                text: 'Events',
                url: '/event'
              },
              {
                text: 'Technologeek',
                url: '/technologeek'
              },
              {
                text: 'Insight',
                url: 'https://insights.g2academy.co/',
                outside: true
              },
              {
                text: 'Contact Us',
                url: '/contact'
              },
              {
                text: 'Career',
                url: '/career'
              },
              {
                text: 'Our Investors',
                url: '/business'
              }
            ]
          }
        ]
      }
    }
  ]
}
