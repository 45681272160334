import { mapGetters } from 'vuex'
import { detectMobile } from '../utils'

export default {
  components: {
    TheLayout: () => import(/* webpackChunkName: "the-layout" */ '@/containers/TheLayout')
  },
  computed: {
    ...mapGetters('common', ['isMobile'])
  },
  async mounted() {
    this.checkPlatform()
  },
  methods: {
    checkPlatform() {
      if (detectMobile()) {
        this.$store.commit('common/setIsMobile', true)
      } else {
        this.$store.commit('common/setIsMobile', false)
      }
    }
  }
}
