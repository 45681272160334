const usp = [
  {
    title: 'Programs',
    description: 'Shaping you into a world-class professional with customized curriculums and dedicated instructors to upgrade your geek potential.',
    icon: { component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Courses'), height: '64', width: '64', color: '#fff' },
    color: 'bg-yellow',
    target: '#program-section'
  },
  {
    title: 'CORPORATE TRAINING',
    description: 'Accelerating productivity to develop high-performing employees and improve cross functional teamwork  within your business.',
    icon: { component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Instructor'), height: '64', width: '64', color: '#fff' },
    color: 'bg-purple',
    target: '#program-section'
  },
  {
    title: 'PRAKERJA',
    description: 'Getting a head start in your career with pre-employment competency classes supported by the  Indonesian government.',
    icon: { component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Certificate'), height: '64', width: '64', color: 'white' },
    color: 'bg-tosca',
    target: '#offer-section'
  },
  {
    title: 'G2LAB',
    description: 'Providing end-to-end digital transformation services to accelerate hiring, strategic collaborations, and corporate projects.',
    icon: { component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Job'), height: '64', width: '64', color: '#fff' },
    color: 'bg-blue',
    target: '#offer-section'
  },
  {
    title: 'PRODUCTS',
    description: 'Developing premier technology solutions dedicated to enhancing your business, services and functions for long-term success.',
    icon: { component: () => import(/* webpackChunkName: "icon" */ '@/components/icons/Product'), height: '64', width: '64', color: '#fff' },
    color: 'bg-blue-light',
    target: '#offer-section'
  }
]

export default usp
