const getAuthHeader = () => {
  return {
    Authorization: 'Bearer ' + localStorage.getItem('access_token'),
    'Access-Control-Allow-Origin': '*'
  }
}

const getOriginHeader = () => {
  return {
    'Access-Control-Allow-Origin': '*'
  }
}

const getAuthAndMultipartHeader = () => {
  return {
    'Content-Type': 'multipart/form-data',
    Authorization: 'Bearer ' + localStorage.getItem('access_token'),
    'Access-Control-Allow-Origin': '*'
  }
}

const getAuthHeaderJWT = () => {
  return {
    Authorization: 'jwt ' + localStorage.getItem('access_token'),
    'Access-Control-Allow-Origin': '*'
  }
}

const getAuthAndMultipartHeaderJWT = () => {
  return {
    'Content-Type': 'multipart/form-data',
    Authorization: 'jwt ' + localStorage.getItem('access_token')
  }
}

export { getAuthHeader, getOriginHeader, getAuthAndMultipartHeader, getAuthHeaderJWT, getAuthAndMultipartHeaderJWT }
