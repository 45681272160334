import axios from 'axios'
import store from '@/store'
import { requestHandler, errorHandlerMock } from '@/api-mock'
const baseApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_PATH,
  headers: {
    common: {
      'X-Reference': 'lms-student',
      'x-g2-timeZone-offset': new Date().getTimezoneOffset()
    }
  }
})

if (process.env.NODE_ENV === 'mock') {
  baseApi.interceptors.request.use(
    (config) => requestHandler(config),
    (error) => Promise.reject(error)
  )
  baseApi.interceptors.response.use(
    (response) => response,
    (error) => errorHandlerMock(error)
  )
} else {
  const responseHandler = (response) => {
    if (response.status === 401) {
      store.dispatch('auth/showUnauthorizedModal')
    }
    return response
  }
  const errorHandler = (error) => {
    if (error.response.status === 401) {
      store.dispatch('auth/showUnauthorizedModal')
    }
    return Promise.reject(error)
  }
  baseApi.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  )
}

export default baseApi
