import Vue from 'vue'
import Vuex from 'vuex'
import common from './modules/common'
import home from './modules/home'
import testimony from './modules/testinomy'
import scholarship from './modules/scholarship'
import partner from './modules/partner'
import prakerja from './modules/prakerja'
import auth from './modules/auth'
import student from './modules/student'
import program from './modules/program'
import instructor from './modules/instructor'
import contact from './modules/contact'
import data from './modules/data'
import marketplace from './modules/marketplace'
import career from './modules/career'
import attendance from './modules/attendance'
import seo from './modules/seo'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    common,
    home,
    testimony,
    scholarship,
    prakerja,
    student,
    program,
    partner,
    instructor,
    contact,
    data,
    marketplace,
    career,
    attendance,
    seo
  }
})
