import { api } from '@/constant'
import baseApi from '@/service/api-v2'
import baseApi1 from '@/service/api-v1'
import baseApiGeneral from '@/service/api-general'
import { getAuthHeader, serializeQueryParams } from '@/utils'

const career = {
  vacancies: (params) => {
    const url = api.career.vacancies + serializeQueryParams(params)
    return baseApi.get(url, {})
  },
  vacancyDetail: (vacancyId) => {
    const url = api.career.vacancyDetail(vacancyId)
    return baseApi.get(url, {})
  },
  applyJob: (vacancyId, payloads, formToken) => {
    const url = api.career.applyJob(vacancyId)
    return baseApiGeneral.post(url, payloads, {
      headers: { 'x-g2-form-token': formToken }
    })
  },
  regencies: (params) => {
    const url = api.career.regencies + serializeQueryParams(params)
    return baseApi1.get(url, {})
  }
}
export default career
