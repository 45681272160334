import api from '@/api'
const state = {
  vacancies: [],
  vacancyDetail: {},
  regencies: []
}
const actions = {
  getVacancies({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      api.career.vacancies(params).then(
        (response) => {
          resolve(response.data)
          commit('setVacancies', response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getVacancyDetail({ commit }, { vacancyId }) {
    return new Promise((resolve, reject) => {
      api.career.vacancyDetail(vacancyId).then(
        (response) => {
          resolve(response.data)
          commit('setVacancyDetail', response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getRegencies({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      api.career.regencies(params).then(
        (response) => {
          resolve(response.data)
          commit('setRegencies', response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  applyJob({ _ }, { vacancyId, payloads, formToken }) {
    return new Promise((resolve, reject) => {
      api.career.applyJob(vacancyId, payloads, formToken).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
const mutations = {
  setVacancies(state, value) {
    state.vacancies = value
  },
  setRegencies(state, value) {
    state.regencies = value
  },
  setVacancyDetail(state, value) {
    state.vacancyDetail = value
  }
}
const getters = {
  vacancies: (state) => state.vacancies,
  vacancyDetail: (state) => state.vacancyDetail,
  regencies: (state) => state.regencies
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
