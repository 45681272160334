import { api } from '@/constant'
import baseApi from '@/service/api-general'
import apiUrlV4 from '@/service/api-url-v4'
import { serializeQueryParams } from '@/utils'

const home = {
  getHomeContent: () => {
    const url = api.homepage.cmsContent
    return baseApi.get(url)
  },
  getPopUpContent: () => {
    const url = api.homepage.popUp
    return apiUrlV4.get(url)
  },
  getProgramContent: (programName, params) => {
    const url = api.homepage.program(programName) + serializeQueryParams(params)
    return baseApi.get(url)
  }
}
export default home
