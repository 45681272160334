import api from '@/api'

const state = {
  content: [],
  userProfile: null,
  myClass: [],
  myClassDetail: {},
  myClassAdditionalInfo: {},
  nonWebinarClassDetail: {},
  needRedemptionCode: {},
  examData: {},
  examDataNonWebinar: {},
  examDataReference: {},
  examDataNonWebinarReference: {},
  isToMyReferralPage: false,
  isContactUsVisible: false
}
const actions = {
  getPrakerjaContent({ commit }) {
    return new Promise((resolve, reject) => {
      api.prakerja.getPrakerjaContent().then(
        (response) => {
          commit('setPrakerjaContent', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getUserProfile({ commit }) {
    return new Promise((resolve, reject) => {
      api.prakerja.getUserProfile().then(
        (response) => {
          commit('setUserProfile', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  redeemCode({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.redeemCode(payloads).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getMyClass({ commit }) {
    const params = {
      page: 0,
      size: 100
    }
    return new Promise((resolve, reject) => {
      api.prakerja.myClass(params).then(
        (response) => {
          commit('setMyClass', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getMyClassDetail({ commit }, { classId }) {
    return new Promise((resolve, reject) => {
      api.prakerja.myClassDetail(classId).then(
        (response) => {
          commit('setMyClassDetail', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getMyClassAdditionalInfo({ commit }, { classId }) {
    return new Promise((resolve, reject) => {
      api.prakerja.myClassAdditionalInfo(classId).then(
        (response) => {
          commit('setMyClassAdditionalInfo', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  updateVideoStatus({ commit }, { studentLmsClassId, activityItemId, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.updateVideoStatus(studentLmsClassId, activityItemId, payloads).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  updateDocumentStatus({ commit }, { studentLmsClassId, activityItemId, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.updateDocumentStatus(studentLmsClassId, activityItemId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  updateLinkStatus({ commit }, { studentLmsClassId, activityItemId, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.updateLinkStatus(studentLmsClassId, activityItemId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  updateRecurringStatus({ commit }, { studentLmsClassId, activityItemId, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.updateRecurringStatus(studentLmsClassId, activityItemId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  updateExamDataNonWebinar({ commit }, { studentLmsClassId, activityItemId, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.updateExamDataNonWebinar(studentLmsClassId, activityItemId, payloads).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getNonWebinarClassDetail({ commit }, { classId }) {
    return new Promise((resolve, reject) => {
      api.prakerja.nonWebinarClassDetail(classId).then(
        (response) => {
          commit('setNonWebinarClassDetail', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  needRedemption({ commit }, { classId, activityItemId }) {
    return new Promise((resolve, reject) => {
      api.prakerja.needRedemptionCode(classId, activityItemId).then(
        (response) => {
          commit('setNeedRedemptionCode', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  sendDropboxQuestion({ _ }, { id, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.sendDropboxQuestion(id, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  absensiPrakerja({ _ }, { id, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.absensiPrakerja(id, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  absensiOffline({ _ }, { studentLmsClassId, activityItemId, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.absensiOffline(studentLmsClassId, activityItemId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  absensiWebinar({ _ }, { studentLmsClassId, activityItemId, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.absensiWebinar(studentLmsClassId, activityItemId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  updateUserProfile({ commit }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.updateUserProfile(payloads).then(
        (response) => {
          commit('setUserProfile', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getExamData({ commit }, { classId, examType }) {
    return new Promise((resolve, reject) => {
      api.prakerja.getExamData(classId, examType).then(
        (response) => {
          commit('setExamData', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getExamDataNonWebinar({ commit }, { studentLmsClassId, activityItemId }) {
    return new Promise((resolve, reject) => {
      api.prakerja.getExamDataNonWebinar(studentLmsClassId, activityItemId).then(
        (response) => {
          commit('setExamDataNonWebinar', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getExamDataReference({ commit }, { classId, examType }) {
    return new Promise((resolve, reject) => {
      api.prakerja.getExamDataReference(classId, examType).then(
        (response) => {
          commit('setExamDataReference', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getExamDataNonWebinarReference({ commit }, { studentLmsClassId, activityItemId }) {
    return new Promise((resolve, reject) => {
      api.prakerja.getExamDataNonWebinarReference(studentLmsClassId, activityItemId).then(
        (response) => {
          commit('setExamDataNonWebinarReference', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  submitExam({ commit }, { classId, examType, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.submitExam(classId, examType, payloads).then(
        (response) => {
          commit('setExamDataReference', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  submitExamNonWebinar({ _ }, { studentLmsClassId, activityItemId, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.submitExamNonWebinar(studentLmsClassId, activityItemId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  uploadTugasNonWebinar({ _ }, { studentLmsClassId, activityItemId, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.uploadTugasNonWebinar(studentLmsClassId, activityItemId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  uploadTugas({ _ }, { classId, payloads }) {
    return new Promise((resolve, reject) => {
      api.prakerja.uploadTugas(classId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  goMyReferralFromHome: ({ commit }, { access }) => {
    commit('setMyReferralPageAccess', access)
  },
  getMyReferral({ _ }) {
    return new Promise((resolve, reject) => {
      api.prakerja.getMyReferral().then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getMyReferralList({ _ }) {
    return new Promise((resolve, reject) => {
      api.prakerja.getMyReferralList().then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  setContactUsVisible({ commit }, value) {
    commit('setContactUsVisible', value)
  }
}
const mutations = {
  setPrakerjaContent(state, value) {
    state.content = value
  },
  setUserProfile(state, value) {
    state.userProfile = value
  },
  setMyClass(state, value) {
    state.myClass = value
  },
  setMyClassDetail(state, value) {
    state.myClassDetail = value
  },
  setMyClassAdditionalInfo(state, value) {
    state.myClassAdditionalInfo = value
  },
  setNonWebinarClassDetail(state, value) {
    state.nonWebinarClassDetail = value
  },
  setNeedRedemptionCode(state, value) {
    state.needRedemptionCode = value
  },
  setExamData(state, value) {
    state.examData = value
  },
  setExamDataNonWebinar(state, value) {
    state.examDataNonWebinar = value
  },
  setExamDataReference(state, value) {
    state.examDataReference = value
  },
  setExamDataNonWebinarReference(state, value) {
    state.examDataNonWebinarReference = value
  },
  setMyReferralPageAccess(state, value) {
    state.isToMyReferralPage = value
  },
  setContactUsVisible(state, value) {
    state.isContactUsVisible = value
  }
}
const getters = {
  content: (state) => state.content,
  tentangPrakerja: (state) => state.content && state.content.tentangPrakerja,
  jaringanKerjaSama: (state) => state.content && state.content.jaringanKerjaSamaPrakerja,
  kelasRekomendasi: (state) => state.content && state.content.kelasRekomendasiPrakerja,
  userProfile: (state) => state.userProfile,
  myClass: (state) => state.myClass,
  myClassDetail: (state) => state.myClassDetail,
  myClassAdditionalInfo: (state) => state.myClassAdditionalInfo,
  nonWebinarClassDetail: (state) => state.nonWebinarClassDetail,
  examData: (state) => state.examData,
  examDataNonWebinar: (state) => state.examDataNonWebinar,
  examDataReference: (state) => state.examDataReference,
  examDataNonWebinarReference: (state) => state.examDataNonWebinarReference,
  getMyReferralPageAccess: (state) => state.isToMyReferralPage,
  isContactUsVisible: (state) => state.isContactUsVisible,
  needRedemptionCode: (state) => state.needRedemptionCode
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
