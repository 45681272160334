import { api } from '@/constant'
import baseApi from '@/service/api-general'

const testimony = {
  getAllTestimonies: () => {
    const url = api.testimony.all
    return baseApi.get(url)
  }
}
export default testimony
