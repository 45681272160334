const programs = [
  {
    id: 'bootcamp',
    title: 'FULL-STACK BOOTCAMP',
    desc: 'Comprising of programs that provide in-depth knowledge and insights on topics most prevalent in the industry.',
    menu: [
      {
        id: 'learning-journey',
        title: 'Learning Journey',
        desc: 'Experience a new perspective to learning at G2Academy. Go beyond conventional textbooks with our engaging lesson plans, interactive activities, and exclusive work placement opportunities.',
        image: require('@/assets/images/journey.png')
      },
      {
        id: 'full-stack-bootcamp',
        title: 'Full-stack Bootcamp',
        desc: 'Programs that give a comprehensive look into the different mechanisms and processes of prominent industry topics.',
        submenu: [
          {
            id: 'web-mobile-development',
            image: require('@/assets/images/courses/courses-fullstack.svg'),
            title: 'Web & Mobile Development',
            desc: 'Excel as a programmer in today’s digital transformation era.',
            buttonText: 'See Details',
            onClick: 'fullstack-web',
            active: true,
            category: 'Full-stack Bootcamp'
          },
          {
            id: 'data-engineer',
            image: require('@/assets/images/courses/courses-data-engineer.svg'),
            title: 'Data Engineering',
            desc: 'Elevate your Data Engineering skills to achieve career-building growth.',
            buttonText: 'See Details',
            onClick: 'data-engineer',
            active: true,
            category: 'Full-stack Bootcamp'
          },
          {
            id: 'data-science',
            image: require('@/assets/images/courses/courses-data-science.svg'),
            title: 'Data Science',
            desc: 'Learn the tools needed to evolve into a multifaceted and innovative Data Scientist.',
            buttonText: 'See Details',
            onClick: 'data-science',
            active: true,
            category: 'Full-stack Bootcamp'
          }
        ]
      }
    ]
  },
  {
    id: 'short-course',
    title: 'SHORT COURSE',
    desc: 'Bringing you a wide variety of the technology and life skills classes  in one compact learning journey.',
    menu: [
      {
        id: 'short-course-item',
        title: null,
        desc: null,
        submenu: [
          {
            id: 'all-short-course',
            image: require('@/assets/images/illustration/all-short-course.png'),
            title: 'Special Program',
            desc: 'Brief but impactful courses designed to help you skill up and stand out.',
            buttonText: 'See Details',
            onClick: '/short-course',
            active: true,
            category: 'Short Course'
          }
        ]
      }
    ]
  },
  {
    id: 'star-talent',
    title: 'STAR TALENT',
    desc: 'Aimed to recognize and support exceptional gifted talents across Indonesia.',
    menu: [
      {
        id: 'star-telent-item',
        title: null,
        desc: null,
        submenu: [
          {
            id: 'scholarship',
            image: require('@/assets/images/courses/courses-scholarship.svg'),
            title: 'Scholarship',
            desc: 'Financial assistance awarded to outstanding and high-achieving individuals.',
            buttonText: 'See Details',
            onClick: '/scholarship',
            active: true,
            category: 'Star Talent'
          }
        ]
      }
    ]
  }
]

export default programs
