import api from '@/api'

const state = {
  lmsAnchor: null,
  surveyVisible: false,
  isLeaveDetails: false,
  isRecallLeaveRequest: false
}
const actions = {
  toAnchor: ({ commit }, { anchor }) => {
    commit('setAnchor', anchor)
  },
  toggleLeaveDetails: ({ commit }, { value }) => {
    commit('setLeaveDetails', value)
  },
  toggleRecallLeaveRequest: ({ commit }, { value }) => {
    commit('setRecallLeaveRequest', value)
  },
  showClassSurvey: ({ commit }) => {
    commit('setSurveyStatus', true)
  },
  createNewLeaveRequest({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.student.createNewLeaveRequest(payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getLeaveRequestList({ _ }, { params }) {
    return new Promise((resolve, reject) => {
      api.student.getLeaveRequestList(params).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  recallLeaveRequest({ _ }, { leaveRequestId, payloads }) {
    return new Promise((resolve, reject) => {
      api.student.recallLeaveRequest(leaveRequestId, payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getLeaveBalanceOptions({ _ }, { customerId }) {
    return new Promise((resolve, reject) => {
      api.student.getLeaveBalanceOptions(customerId).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getAdministration({ _ }) {
    return new Promise((resolve, reject) => {
      api.student.getAdministration().then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getAdministrationDetail({ _ }, { registrationId }) {
    return new Promise((resolve, reject) => {
      api.student.getAdministration(registrationId).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  uploadRegistrationFile({ _ }, { registrationId, registrationDocumentId, payloads }) {
    return new Promise((resolve, reject) => {
      api.student.uploadRegistrationFile(registrationId, registrationDocumentId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getSchedule({ _ }, { params, formToken }) {
    return new Promise((resolve, reject) => {
      api.student.getSchedule(params, formToken).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getMyClass({ _ }, { params }) {
    return new Promise((resolve, reject) => {
      api.student.getMyClass(params).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getScheduleDashboard({ _ }, { classId, params }) {
    return new Promise((resolve, reject) => {
      api.student.getScheduleDashboard(classId, params).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getMessageList({ _ }, { classId, params }) {
    return new Promise((resolve, reject) => {
      api.student.getMessageList(classId, params).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  replyMessage({ _ }, { messageTopicId, payloads }) {
    return new Promise((resolve, reject) => {
      api.student.replyMessage(messageTopicId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getStudentDetail({ _ }, { studentId }) {
    return new Promise((resolve, reject) => {
      api.student.getStudentDetail(studentId).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getClassById({ _ }, { classId }) {
    return new Promise((resolve, reject) => {
      api.student.getClassById(classId).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getScheduleOnward({ _ }, { classId }) {
    return new Promise((resolve, reject) => {
      api.student.getScheduleOnward(classId).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  createAttendance({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.student.createAttendance(payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  doTheTask({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.student.doTheTask(payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  doTheExam({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.student.doTheExam(payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  updateTheTask({ _ }, { submissionId, payloads }) {
    return new Promise((resolve, reject) => {
      api.student.updateTheTask(submissionId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  updateTheExam({ _ }, { submissionId, payloads }) {
    return new Promise((resolve, reject) => {
      api.student.updateTheExam(submissionId, payloads).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  uploadImage({ _ }, { data }) {
    return new Promise((resolve, reject) => {
      api.student.uploadImage(data).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  uploadFile({ _ }, { data }) {
    return new Promise((resolve, reject) => {
      api.student.uploadFile(data).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  updateStudent({ _ }, { studentId, payloads }) {
    return new Promise((resolve, reject) => {
      api.student.updateStudent(studentId, payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  updateEmailStudent({ _ }, { studentId, payloads }) {
    return new Promise((resolve, reject) => {
      api.student.updateEmailStudent(studentId, payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  createRedeemRegister({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.student.createRedeemRegister(payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
const mutations = {
  setAnchor(state, value) {
    state.lmsAnchor = value
  },
  setLeaveDetails(state, value) {
    state.isLeaveDetails = value
  },
  setSurveyStatus(state, value) {
    state.surveyVisible = value
  },
  setRecallLeaveRequest(state, value) {
    state.isRecallLeaveRequest = value
  }
}
const getters = {
  lmsAnchor: (state) => state.lmsAnchor,
  surveyVisible: (state) => state.surveyVisible,
  isLeaveDetails: (state) => state.isLeaveDetails,
  isRecallLeaveRequest: (state) => state.isRecallLeaveRequest
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
