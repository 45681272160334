const prakerjaFaq = [
  {
    id: '1',
    text: 'Siapa saja yang bisa daftar untuk Program Kartu Prakerja?',
    active: false,
    submenu: {
      message:
        '<ul><li>Semua WNI yang berusia 18 tahun ke atas dan tidak sedang mengikuti pendidikan formal.</li><li>Pencari kerja dan pekerja/buruh yang terkena PHK.</li><li>Pekerja/buruh yang membutuh peningkatan kompetensi kerja, seperti pekerja/buruh yang dirumahkan dan pekerja bukan penerima upah. Termasuk pelaku usaha mikro dan kecil.</li><li>Untuk merespon dampak dari pandemi COVID-19, Program Kartu Prakerja, untuk sementara waktu, akan diprioritaskan bagi pekerja/buruh yang dirumahkan maupun pelaku usaha mikro dan kecil yang terdampak penghidupannya.</li><li>Selain itu, dalam 1 (satu) Kartu Keluarga hanya diperbolehkan maksimal 2 (dua) NIK yang menjadi Penerima Kartu Prakerja. Jadi, pastikan kalau hanya ada maksimal 2 (dua) anggota keluarga Anda yang menjadi Penerima Kartu Prakerja.</li><li>Difabel juga dianjurkan untuk mendaftar dan mengikuti Program Kartu Prakerja.</li></ul>'
    }
  },
  {
    id: '2',
    text: 'Siapa saja yang TIDAK bisa daftar untuk Program Kartu Prakerja?',
    active: false,
    submenu: {
      message:
        '<ul><li>WNI yang di bawah 18 tahun. </li><li>WNI dengan pekerjaan: <ul><li>Pejabat Negara</li><li>Pimpinan dan Anggota Dewan Perwakilan Rakyat Daerah</li><li>Aparatur Sipil Negara</li><li>Prajurit Tentara Nasional Indonesia</li><li>Anggota Kepolisian Negara Republik Indonesia</li><li>Kepala Desa dan perangkat desa</li><li>Direksi, Komisaris, dan Dewan Pengawas pada badan usaha milik negara atau badan usaha milik daerah.</li></ul></li>'
    }
  },
  {
    id: '3',
    text: 'Bagaimana jika saya belum menerima insentif padahal sudah ada jadwal pencairan insentif dalam dashboard saya?',
    active: false,
    submenu: {
      message:
        'Pencairan insentif dilakukan secara bertahap. Oleh karena itu, pastikan kamu sudah memenuhi seluruh syarat dan ketentuan yang berlaku untuk mendapatkan insentif. Lakukan juga pengecekan berkala di dashboard akun kamu dan riwayat rekening e-wallet/bank yang telah terhubung dengan akun Prakerja. Jika dalam waktu 5 (lima) hari kerja Anda masih belum menerima insentif, silakan hubungi kami melalui Formulir Pengaduan.'
    }
  },
  {
    id: '4',
    text: 'Mengapa saya tidak mendapat jadwal pengisian survei evaluasi?',
    active: false,
    submenu: {
      message:
        'Jadwal pengisian survei evaluasi hanya diberikan kepada Penerima Kartu Prakerja yang berhasil mendapat insentif biaya mencari kerja yang pertama. Pastikan Anda sudah menerima insentif tersebut dan cek terus dashboard untuk mengetahui jadwal terbaru.'
    }
  },
  {
    id: '5',
    text: 'Mengapa nama di sertifikat tidak sesuai dengan nama saya?',
    active: false,
    submenu: {
      message:
        'Sertifikat pelatihan diterbitkan langsung oleh Lembaga Pelatihan (G2Academy). Jika ada kekeliruan dalam penulisan nama di sertifikat pelatihan, Anda bisa menghubungi kami untuk konfirmasi lebih lanjut. Selain itu, pastikan Anda menggunakan nama asli di profil akun Platform Digital dan Lembaga Pelatihan agar nama di sertifikat sama dengan nama asli Anda.'
    }
  },
  {
    id: '6',
    text: 'Apa yang harus saya lakukan setelah menyelesaikan pelatihan?',
    active: false,
    submenu: {
      message:
        'Setelah menyelesaikan pelatihan dan mendapatkan sertifikat, segera mengisi rating dan ulasan pelatihan di dashboard Anda. Pastikan memberi rating dan ulasan dengan jujur sesuai pengalaman pelatihan Anda.'
    }
  },
  {
    id: '7',
    text: 'Saya sudah menyelesaikan pelatihan, tapi kenapa sertifikat belum muncul di dashboard Prakerja?',
    active: false,
    submenu: {
      message: 'Pelaporan Sertifikat Anda membutuhkan waktu 3-7 hari kerja sejak tanggal penyelesaian pelatihan. Silakan di cek secara berkala pada dashboard Prakerja dalam waktu tersebut.'
    }
  },
  {
    id: '8',
    text: 'Apa yang harus dilakukan ketika saya gagal login atau lupa password?',
    active: false,
    submenu: {
      message:
        '<ul><li>Klik <strong>Masuk</strong> pada halaman depan situs Kartu Prakerja. (<a class="text-yellow font-semibold" href="https://www.g2academy.co/login#prakerja">https://www.g2academy.co/login#prakerja</a>) . Lalu klik <strong>Lupa Password</strong>. </li><li>Masukkan alamat email lalu klik <strong>Kirim</strong>.</li><li>Anda akan menerima email berisi password baru.</li><li>Masukkan password baru.</li><li>Klik <strong>Atur Ulang</strong>. </li><li>Password berhasil diganti. Silakan login kembali dengan password baru.</li></ul>'
    }
  },
  {
    id: '9',
    text: 'Bagaimana cara login ke LMS saya?',
    active: false,
    submenu: {
      message:
        '<ul><li>Silakan login dengan link berikut <a class="text-yellow font-semibold" href="https://www.g2academy.co/login#prakerja">https://www.g2academy.co/login#prakerja</a>.</li><li>Gunakan Username dan Password yang dikirimkan melalui email/SMS.</li><li>Jika berhasil masuk, semua informasi, absensi, link zoom, status pelatihan, dan lain-lainnya terdapat di dalam dashboard G2Academy.</li><li>Jika password lupa/tidak ada, klik <strong>Lupa Password</strong>, kemudian input email dan cek inbox/spam email beberapa saat kemudian.</li></ul>'
    }
  },
  {
    id: '10',
    text: 'Kapan jadwal kelas Pelatihan?',
    active: false,
    submenu: {
      message: 'Seluruh informasi pelatihan tersedia dalam Akun LMS Anda. Login ke LMS menggunakan Username dan Password yang dikirimkan melalui email/SMS.'
    }
  },
  {
    id: '11',
    text: 'Mengapa pencairan insentif saya belum terjadwal?',
    active: false,
    submenu: {
      message:
        'Jika belum ada jadwal pencairan insentif di dashboard, pastikan kembali bahwa Anda sudah menyelesaikan pelatihan serta memberikan rating dan ulasan. Silakan menunggu 1x24 jam untuk sinkronisasi jadwal pencairan insentif.'
    }
  },
  {
    id: '12',
    text: 'Bagaimana cara redeem voucher saya?',
    active: false,
    submenu: {
      message:
        '<ul><li>Kunjungi halaman prakerja di www.g2academy.co/prakerja dan klik "Tukar Voucher".</li><li>Mohon masukkan alamat email yang benar dan tidak typo (Sesuai yang terdaftar pada dashboard Prakerja).</li><li>Setelah 1x24 jam maksimal Anda akan menerima email berisi Username dan Password untuk mengikuti pelatihan.</li><li>Silahkan login di www.g2academy.co/login menggunakan user dan password yang sesuai pada email yang diterima.</li></ul>'
    }
  },
  {
    id: '13',
    text: 'Bagaimana jika kelas terlewat?',
    active: false,
    submenu: {
      message: 'Jika jadwal kelas terlewat, silakan mengikuti pelatihan via zoom di minggu depan dengan waktu, hari dan jam yang sama.'
    }
  },
  {
    id: '14',
    text: 'Bagaimana cara saya melihat kode voucher?',
    active: false,
    submenu: {
      message: 'Kode voucher bisa didapatkan di Platform Digital (misal: Bukalapak, Kariermu) dan dilihat di bagian Riwayat Pembelian.'
    }
  },
  {
    id: '15',
    text: 'Bagaimana cara saya melihat jadwal kelas?',
    active: false,
    submenu: {
      message:
        '<ul><li>Silahkan login di www.g2academy.co/login menggunakan user dan password yang sesuai pada email yang diterima.</li><li>Jadwal pelatihan dapat di cek melalui profil dan melihat kelas pada LMS G2Academy di www.g2academy.co/user/my-classes.</li></ol>'
    }
  },
  {
    id: '16',
    text: 'Bagaimana cara saya melakukan absensi?',
    active: false,
    submenu: {
      message:
        '<ul><li>Silahkan login di www.g2academy.co/login menggunakan user dan password yang sesuai pada email yang diterima.</li><li>Lihat jadwal kelas pada LMS G2Academy di www.g2academy.co/user/my-classes.</li><li>Absensi dan link zoom dapat diakses di jadwal yang tertera.</li></ol>'
    }
  }
]
export default prakerjaFaq
