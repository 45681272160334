import { api } from '@/constant'
import baseApi from '@/service/api-general'
import apiUrlCRM from '@/service/api-crm'

const scholarship = {
  getScholarship: () => {
    const url = api.scholarship.default
    return baseApi.get(url)
  },
  postScholarship: (payload) => {
    return apiUrlCRM.post('/web/scholarships', payload, { headers: { Authorization: 'Bearer' } })
  }
}
export default scholarship
