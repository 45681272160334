const classFaq = [
  {
    id: '1',
    text: 'Am I eligible to enroll <span class="text-yellow font-semibold">without an IT background</span>?',
    active: false,
    submenu: {
      message: 'Yes, of course. You can learn the basics in our LoOPs and Pramp programs first before joining our Full-stack Bootcamp!'
    }
  },
  {
    id: '2',
    text: 'Is there a <span class="text-yellow font-semibold">scholarship program</span>?',
    active: false,
    submenu: {
      message: 'We provide scholarship opportunities for exceptional and high-achieving prospective students.'
    }
  },
  // {
  //   id: '3',
  //   text: 'I’m <span class="text-yellow font-semibold">still a university student</span>, can I join the program?',
  //   active: false,
  //   submenu: {
  //     message: 'Yes, you can! Our programs are available for anyone, even university students.'
  //   }
  // },
  {
    id: '4',
    text: '<span class="text-yellow font-semibold">I’m currently working</span>, can I still join the program?',
    active: false,
    submenu: {
      message: 'Of course! We even provide part-time classes to better accommodate your schedule.'
    }
  },
  {
    id: '5',
    text: 'What are <span class="text-yellow font-semibold">the requirements for applying scholarship</span>?',
    active: false,
    submenu: {
      message:
        'Maximum  25 years old at the time of registration and currently not studying or continuing onto a higher-education degree. Our scholarship also doesn’t apply to those holding a scholarship program elsewhere, involved in other training/courses, or have work commitment with the government.'
    }
  },
  {
    id: '6',
    text: 'How much does <span class="text-yellow font-semibold">the Full-stack Bootcamp cost</span>?',
    active: false,
    submenu: {
      message: 'It depends on which Full-stack Bootcamp you wish to take. Each of them has a differenct price.'
    }
  },
  {
    id: '7',
    text: 'Is there any <span class="text-yellow font-semibold">SNPL (Study Now, Pay Later) program</span>?',
    active: false,
    submenu: {
      message: 'We do offer a Study Now, Pay Later scheme whereby our students can pay after graduating or begin working.'
    }
  }
]
export default classFaq
