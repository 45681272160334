import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadTranslationMessages () {
  const translations = require.context(
    './languages',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  )
  const messages = {}
  translations.keys().forEach((key) => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const translation = matched[1]
      messages[translation] = translations(key)
    }
  })
  return messages
}

export default new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: loadTranslationMessages()
})
