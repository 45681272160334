export const scrollToTheTarget = (target, offset) => {
  const yOffset = offset
  let element = ''
  let destination = 0
  if (target) {
    element = document.getElementById(target.replace('#', ''))
    destination = element.getBoundingClientRect().top + window.pageYOffset + yOffset
  }

  window.scrollTo({ top: destination, behavior: 'smooth' })
}
