const registrationDocument = {
  main: [
    {
      title: 'Bootcamp Proposal',
      description: 'Bootcamp Proposal merupakan dokumen awal yang digunakan sebagai acuan untuk student dalam memilih program Bootcamp.',
      provision: false
    },
    {
      title: 'Payment Scheme',
      description: 'G2Academy menyediakan beberapa pilihan metode pembayaran untuk memudahkan student membayarkan biaya investasi pendidikan. Payment Scheme merupakan dokumen berisi informasi lengkap terkait masing-masing metode pembayaran.',
      provision: false
    },
    {
      title: 'Invoice',
      description: 'Invoice merupakan dokumen berisi tagihan biaya pendidikan yang meliputi nominal tagihan dan informasi nomor rekening pembayaran.',
      provision: false
    },
    {
      title: 'KTP/SIM/PASSPORT',
      description: 'KTP Siswa diperlukan untuk dimasukkan ke dalam ISA Agreement. Silakan unggah dokumen berikut sebagai persyaratan administrasi kelas sesuai dengan ketentuan.',
      provision: 'file'
    },
    {
      title: 'KTP Wali',
      description: 'KTP Wali dperlukan untuk dimasukkan ke dalam ISA Agreement sebagai Penjamin student. Silakan unggah dokumen berikut sebagai persyaratan administrasi kelas sesuai dengan ketentuan.',
      provision: 'file'
    },
    {
      title: 'Photo',
      description: 'Silakan unggah dokumen berikut sebagai persyaratan administrasi kelas sesuai dengan ketentuan.',
      provision: 'image'
    },
    {
      // eslint-disable-next-line quotes
      title: `Student's Commitment`,
      description: 'Student Commitment merupakan dokumen wajib yang harus dibaca dan ditandatangan oleh student sebelum memulai Bootcamp. Dokumen ini berisi syarat dan ketentuan mengikuti Bootcamp, hak dan kewajiban student, peraturan, serta informasi lainnya yang berkaitan dengan proses pelatihan.',
      provision: 'specific'
    },
    {
      title: 'ISA Agreement',
      description: 'Khusus student yang memilih metode pembayaran ISA, ISA Agreement merupakan dokumen wajib yang harus dibaca dan ditandatangan oleh student sebelum memulai Bootcamp. Dokumen ini berisi syarat dan ketentuan mengikuti Bootcamp, hak dan kewajiban student, peraturan, informasi Ikatan Dinas, serta informasi lainnya yang berkaitan dengan proses pelatihan.',
      provision: 'specific'
    },
    {
      title: 'Surat Rekomendasi Kepala Sekolah',
      description: 'Silakan kumpulkan dokumen yang diperlukan untuk menyelesaikan proses administrasi.',
      provision: 'specific'
    }
  ],
  provision: {
    file: ['Format file JPG/PDF', 'Beresolusi tinggi agar tulisan terbaca', 'Maksimum ukuran file 5 MB'],
    image: ['Format file yang diterima JPG', 'Ukuran pas foto 3x4 dengan background biru atau merah', 'Maksimum ukuran file 5 MB'],
    specific: ['Silakan unduh dokumen di', 'Sertakan tandatangan dan tanggal di bagian yang sudah disediakan oleh G2Academy']
  }
}

export default registrationDocument
