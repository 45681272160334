import axios from 'axios'
import store from '@/store'
const apiUrlHR = axios.create({
  baseURL: process.env.VUE_APP_API_URL_BASE_HR,
  headers: {
    common: {
      'X-Reference': 'lms-student',
      'x-g2-timeZone-offset': new Date().getTimezoneOffset()
    }
  }
})

const responseHandler = (response) => {
  if (response?.status === 401) {
    store.dispatch('auth/showUnauthorizedModal')
  }
  return response
}

const errorHandler = (error) => {
  if (error?.response?.status === 401) {
    store.dispatch('auth/showUnauthorizedModal')
  }
  return Promise.reject(error)
}

apiUrlHR.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
)

export default apiUrlHR
