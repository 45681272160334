import api from '@/api'

const state = {
  instructorDetail: {}
}
const actions = {
  getInstructorById({ commit }, { id }) {
    return new Promise((resolve, reject) => {
      api.instructor.getInstructorById(id).then(
        (response) => {
          commit('setInstructorDetail', response.data.data)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
const mutations = {
  setInstructorDetail(state, value) {
    state.instructorDetail = value
  }
}
const getters = {
  instructorDetail: (state) => state.instructorDetail
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
