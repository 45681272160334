export const truncate = (string, length) => {
  return string.length > length ? string.substring(0, length - 3) + '...' : string
}

export const capitalize = (string) => {
  if (string) {
    const arr = string.toLowerCase().split(' ')
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    return arr.join(' ')
  }
  return ''
}

export const upperCaseFirstWord = (text) => {
  if (!text) return ''
  // Split the input string by underscore ('_') or space (' ') to get individual words
  const words = text.split(/[_\s]+/)
  // Capitalize the first letter of each word and convert the rest of the letters to lowercase
  const convertedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
  // Join the converted words with a space to form the converted string
  const convertedText = convertedWords.join(' ')
  return convertedText
}
