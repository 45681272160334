import { addMock, enableMocking } from '@/api-mock'
import home from './home'
import testimony from './testimony'
import scholarship from './scholarship'
import partner from './partner'
import prakerja from './prakerja'
import auth from './auth'
import student from './student'
import program from './program'
import instructor from './instructor'
import contact from './contact'
import data from './data'
import marketplace from './marketplace'
import career from './career'
import attendance from './attendance'
import seo from './seo'

if (process.env.NODE_ENV === 'mock') {
  addMock()
  enableMocking(true)
}

const api = {
  home,
  testimony,
  scholarship,
  partner,
  prakerja,
  auth,
  student,
  program,
  instructor,
  contact,
  data,
  marketplace,
  career,
  attendance,
  seo
}

export default api
