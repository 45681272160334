import api from '@/api'

const state = {
  testimonies: []
}
const actions = {
  getAllTestimonies({ commit }) {
    return new Promise((resolve, reject) => {
      api.testimony.getAllTestimonies().then(
        (response) => {
          commit('setTestimonies', response.data.data)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
const mutations = {
  setTestimonies(state, value) {
    state.testimonies = value
  }
}
const getters = {
  testimonies: (state) => state.testimonies
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
