import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

const formatFullDate = (value) => {
  if (value) {
    return dayjs(String(value)).format('DD MMMM YYYY')
  }
}
const formatDate = (value) => {
  if (value) {
    return dayjs(String(value)).format('DD MMM YYYY')
  }
}
const formatFullDateMinusDay = (value, days) => {
  if (value) {
    return dayjs(String(value)).subtract(days, 'days').format('DD MMMM YYYY')
  }
}

const utcToLocal = (day, format = '') => {
  const localTime = dayjs.utc(day).local().format(format)
  return localTime
}

const localToUTC = (localTime, format = '') => {
  return dayjs(localTime).utc().format(format)
}

export { formatDate, formatFullDate, formatFullDateMinusDay, utcToLocal, localToUTC }
