export const isNotEmptyError = (values) => {
  const errors = []
  for (const i in values) {
    const v = values[i]
    if (typeof v === 'undefined' || v === null || v.toString().trim() === '') {
      errors.push({
        field: i,
        error: "can't be empty!"
      })
    }
  }
  return errors
}
