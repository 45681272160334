import { api } from '@/constant'
import apiV2 from '@/service/api-v2'
import { getAuthHeader, serializeQueryParams } from '@/utils'

const seo = {
  seoContent: (params) => {
    const url = api.seo.content + serializeQueryParams(params)
    return apiV2.get(url, { headers: getAuthHeader() })
  },
  getSeoList: (params) => {
    const url = api.seo.list + serializeQueryParams(params)
    return apiV2.get(url, { headers: getAuthHeader() })
  }
}
export default seo
