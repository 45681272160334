import api from '@/api'

const state = {
  partnerDetail: {},
  agreementList: []
}
const actions = {
  getPartnerDetail: ({ commit }, { id }) => {
    return new Promise((resolve, reject) => {
      api.partner.getPartnerDetail(id).then(
        (response) => {
          commit('setPartnerDetail', response.data.data)
          resolve(response.data.data)
        },
        (error) => reject(error)
      )
    })
  },
  getAllAgreement: ({ commit }) => {
    return new Promise((resolve, reject) => {
      api.partner.getAllAgreement().then(
        (response) => {
          commit('setAllAgreement', response.data.data)
          resolve(response.data.data)
        },
        (error) => reject(error)
      )
    })
  }
}
const mutations = {
  setPartnerDetail(state, value) {
    state.partnerDetail = value
  },
  setAllAgreement(state, value) {
    state.agreementList = value
  }
}
const getters = {
  getPartnerDetail: (state) => state.partnerDetail,
  getAgreementList: (state) => state.agreementList
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
