import axios from 'axios'
import store from '@/store'
let api = ''
const customApi = {
  setApi: (value) => (api = value),
  getApi: () =>
    axios.create({
      baseURL: api,
      headers: {
        common: {
          'X-Reference': 'lms-student',
          'x-g2-timeZone-offset': new Date().getTimezoneOffset()
        }
      }
    })
}

const responseHandler = (response) => {
  if (response.status === 401) {
    store.dispatch('auth/showUnauthorizedModal')
  }
  return response
}

const errorHandler = (error) => {
  if (error.response.status === 401) {
    store.dispatch('auth/showUnauthorizedModal')
  }
  return Promise.reject(error)
}

customApi.getApi().interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
)

export { customApi }
