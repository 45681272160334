import axios from 'axios'
import { api } from '@/constant'
import { getAuthHeader } from '@/utils'
import baseApi from '@/service/api-general'

const partner = {
  getPartnerDetail: (id) => {
    const url = api.partner.getPartnerDetail(id)
    return baseApi.get(url, { headers: getAuthHeader() })
  },
  getAllAgreement: () => {
    const url = api.partner.getAllAgreement
    return baseApi.get(url, { headers: getAuthHeader() })
  }
}
export default partner
