import api from '@/api'
const state = {}
const actions = {
  postAttendances({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.attendance.postAttend(payloads).then(
        (response) => resolve(response.data),
        (error) => reject(error)
      )
    })
  }
}
const mutations = {}
const getters = {}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
