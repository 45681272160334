import { api } from '@/constant'

export default [
  {
    method: 'GET',
    url: api.homepage.cmsContent,
    response: {
      status: 200,
      statusText: 'OK',
      data: {
        data: 'This is dummy GET'
      }
    }
  },
  {
    method: 'POST',
    url: api.homepage.cmsContent,
    response: {
      status: 200,
      statusText: 'OK',
      data: {
        data: 'This is dummy POST'
      }
    }
  }
]
