import api from '@/api'
const state = {
  adminGeneral: '6285775475917',
  adminB2C: '6281388223105',
  adminCareerPage: '6285932270730',
  emailGeneral: 'cs@g2academy.co',
  locationLink:
    'https://www.google.com/maps/dir//Wisma+Barito+Pacific,+Tower+A+Level+Ground,+Jl.+Letjen+S.+Parman,+Slipi,+Jakarta+Barat/@-6.1948269,106.7976109,16z/data=!4m8!4m7!1m0!1m5!1m1!1s0x2e69f6958ac2c5f9:0x76d1835570a756ad!2m2!1d106.798587!2d-6.1975678',
  contentLanguage: { id: 0, name: 'ina' },
  isMobile: false,
  isNavOpen: false,
  isNavBottomOpen: false,
  slug: '',
  loading: false,
  thankYouPageTitle: {
    title: null
  },
  isPhotoChanged: false
}
const actions = {
  sendWhatsApp({ _ }, { number, topic }) {
    const intro = `https://wa.me/${number}?text=Halo%20G2Academy%21%0D%0ASaya%20ingin%20mendapatkan%20informasi%20mengenai%20`
    topic ? window.open(intro + topic) : window.open(`${intro}program%20yang%20ada%20di%20G2Academy.`)
  },
  sendWhatsAppPrakerja({ _ }, { number, mitra, topic }) {
    const intro = `https://wa.me/${number}?text=Halo%20${mitra}%21%0D%0ASaya%20ingin%20bertanya%20tentang%20kelas%20`
    topic ? window.open(intro + topic) : window.open(`${intro}`)
  },
  sendWhatsAppInstallment({ _ }, { number }) {
    const intro = `https://wa.me/${number}?text=Halo%20G2Academy!%20Saya%20ingin%20mendapatkan%20informasi%20mengenai%20metode%20pembayaran%20Cicilan%20yang%20ada%20di%20G2Academy`
    window.open(`${intro}`)
  },
  toggleSideBar: ({ commit }) => {
    commit('toggleSideBar')
  },
  toggleNavBottom: ({ commit }) => {
    commit('toggleNavBottom')
  },
  changeSlug: ({ commit }, { slug }) => {
    commit('setSlug', slug)
  },
  getUploadUrl({ _ }, { params }) {
    return new Promise((resolve, reject) => {
      api.data.getUploadUrl(params).then(
        (response) => {
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  updateUploadFile({ _ }, { url, data, contentType }) {
    return new Promise((resolve, reject) => {
      api.data.updateUploadFile(url, data, contentType).then(
        (response) => {
          resolve(response.body)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  showLoading: ({ commit }) => {
    commit('setLoadingStatus', true)
  },
  hideLoading: ({ commit }) => {
    commit('setLoadingStatus', false)
  },
  setThankYouPage: ({ commit }, { data }) => {
    commit('setThankYouPageTitle', data)
  },
  setPhotoChanged: ({ commit }, { data }) => {
    commit('setPhotoChanged', data)
  }
}
const mutations = {
  setAdminGeneral(state, value) {
    state.adminGeneral = value
  },
  setAdminB2C(state, value) {
    state.adminB2C = value
  },
  setEmailGeneral(state, value) {
    state.emailGeneral = value
  },
  setLocationLink(state, value) {
    state.locationLink = value
  },
  setContentLanguage(state, value) {
    state.contentLanguage = value
  },
  setIsMobile(state, value) {
    state.isMobile = value
  },
  toggleSideBar(state) {
    state.isNavOpen = !state.isNavOpen
  },
  toggleNavBottom(state) {
    state.isNavBottomOpen = !state.isNavBottomOpen
  },
  setSlug(state, value) {
    state.slug = value
  },
  setLoadingStatus(state, value) {
    state.loading = value
  },
  setThankYouPageTitle(state, value) {
    state.thankYouPageTitle = value
  },
  setPhotoChanged(state, value) {
    state.isPhotoChanged = value
  }
}
const getters = {
  adminGeneral: (state) => state.adminGeneral,
  adminB2C: (state) => state.adminB2C,
  emailGeneral: (state) => state.emailGeneral,
  locationLink: (state) => state.locationLink,
  contentLanguage: (state) => state.contentLanguage,
  adminCareerPage: (state) => state.adminCareerPage,
  isMobile: (state) => state.isMobile,
  isNavOpen: (state) => state.isNavOpen,
  slug: (state) => state.slug,
  loading: (state) => state.loading,
  thankYouPageTitle: (state) => state.thankYouPageTitle,
  isPhotoChanged: (state) => state.isPhotoChanged,
  isNavBottomOpen: (state) => state.isNavBottomOpen
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
