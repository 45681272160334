import { api } from '@/constant'
import { getAuthHeader, serializeQueryParams } from '@/utils'
import { customApi } from '@/service/custom-api'
import apiUrlV4 from '@/service/api-url-v4'
import baseApi from '@/service/api-general'

const data = {
  getUploadUrl: (params) => {
    const url = api.data.getUploadUrl(serializeQueryParams(params))
    return apiUrlV4.get(url, {
      headers: getAuthHeader()
    })
  },
  updateUploadFile: (url, data, contentType) => {
    customApi.setApi(url)
    return customApi.getApi().put(null, data, { headers: { 'Content-Type': `${contentType}` } })
  },
  educationalInstitutions: (slug) => {
    const url = api.data.educationalInstitutions
    if (slug.length > 0) {
      return baseApi.get(url + '?slug=' + slug)
    }
    return baseApi.get(url)
  },
  digitalPlatforms: (slug) => {
    const url = api.data.digitalPlatforms
    if (slug.length > 0) {
      return baseApi.get(url + '?slug=' + slug)
    }
    return baseApi.get(url)
  }
}
export default data
