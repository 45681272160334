import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'

// TODO: http://localhost:10001/student/class/2c9f963081c6d2ad0181c7166d820012/prakerja

Vue.use(VueRouter)

async function prerender(to, from, next) {
  const fullHost = location.protocol + '//' + location.host
  const result = await fetch(`${process.env.VUE_APP_BASE_PATHV2}/seo-page?url=${to.path}`)
    .then((response) => response.json())
    .then((response) => {
      if (response.code === 200) {
        return response.data
      } else {
        throw new Error(response.status + ' Failed Fetch ')
      }
    })
    .catch(() => {
      return {
        page_name: 'G2Academy - World Class Technology Courses',
        description: 'G2Academy empowers digital transformation by developing world-class tech talents across all levels, elements and sectors of the industry',
        thumbnail_link: `${fullHost}/Thumbnail-General.png`
      }
    })
  to.meta.title = result.page_name
  const meta = [
    {
      name: 'description',
      content: result.description
    },
    {
      property: 'og:description',
      content: result.description
    },
    {
      property: 'og:title',
      content: result.page_name
    },
    {
      property: 'og:image',
      content: result.thumbnail_link || `${fullHost}/Thumbnail-General.png`
    },
    {
      property: 'twitter:description',
      content: result.description
    },
    {
      property: 'twitter:title',
      content: result.page_name
    },
    {
      property: 'twitter:image',
      content: result.thumbnail_link || `${fullHost}/Thumbnail-General.png`
    }
  ]
  to.meta.metaTags = meta
  return next()
}

const routes = [
  {
    path: '*',
    name: 'page-not-found',
    component: () => import(/* webpackChunkName: "404" */ '@/views/404/404.vue')
  },
  {
    path: '/redeem',
    name: 'redeem',
    component: () => import(/* webpackChunkName: "redeem" */ '@/views/Redeem/Redeem.vue'),
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  // {
  //   path: '/isw-voucher',
  //   name: 'ISW Voucher',
  //   component: () => import(/* webpackChunkName: "redeem isw" */ '@/views/Redeem/RedeemISW.vue')
  // },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home-page" */ '@/views/Home/Home.vue'),
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/login-prakerja',
    name: 'Login Prakerja',
    redirect: '/login',
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/Authentication/Login.vue'),
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '@/views/Authentication/Register.vue'),
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/register-alliance',
    name: 'RegisterMitra',
    component: () => import(/* webpackChunkName: "register-alliance" */ '@/views/Authentication/RegisterMitra.vue'),
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/au',
    name: 'Authentication',
    components: {
      default: () => import(/* webpackChunkName: "authentication" */ '@/views/Authentication/Authentication.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/aus',
    name: 'Authentication Admin',
    components: {
      default: () => import(/* webpackChunkName: "authentication" */ '@/views/Authentication/AdminAuthentication.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/about',
    name: 'About',
    components: {
      default: () => import(/* webpackChunkName: "about" */ '@/views/About/About.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/programs',
    name: 'Programs',
    meta: {
      title: 'this is my title'
    },
    components: {
      default: () => import(/* webpackChunkName: "Programs" */ '@/views/Programs/AllPrograms.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/courses',
    name: 'Courses',
    components: {
      default: () => import(/* webpackChunkName: "courses" */ '@/views/Programs/Programs.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/loan',
    name: 'Loan',
    components: {
      default: () => import(/* webpackChunkName: "loan" */ '@/views/Loan/Loan.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/loan/pintek',
    name: 'Pintek',
    components: {
      default: () => import(/* webpackChunkName: "pintek" */ '@/views/Loan/Pintek.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/study-now-pay-later',
    name: 'SNPL',
    components: {
      default: () => import(/* webpackChunkName: "snpl" */ '@/views/Loan/SNPL.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/tech-champ-scholarship',
    name: 'Scholarship',
    components: {
      default: () => import(/* webpackChunkName: "scholarship" */ '@/views/Scholarship/Scholarship.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/indo-digi',
    name: 'IndoDigi',
    components: {
      default: () => import(/* webpackChunkName: "IndoDigi" */ '@/views/Scholarship/IndoDigi.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/corporate-training',
    name: 'Corporate Training',
    components: {
      default: () => import(/* webpackChunkName: "corporate-training" */ '@/views/Static/CorporateTraining.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/isa',
    name: 'ISA',
    components: {
      default: () => import(/* webpackChunkName: "isa" */ '@/views/Loan/ISA.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/g2lab',
    name: 'G2Lab',
    components: {
      default: () => import(/* webpackChunkName: "g2lab" */ '@/views/Static/G2Lab.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/corporate-solutions',
    name: 'Corporate Solutions',
    components: {
      default: () => import(/* webpackChunkName: "corporate-solutions" */ '@/views/Static/CorporateSolutions.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/government',
    name: 'Government',
    components: {
      default: () => import(/* webpackChunkName: "government" */ '@/views/Static/Government.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/education-institutes',
    name: 'Education',
    components: {
      default: () => import(/* webpackChunkName: "education" */ '@/views/Static/Education.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/open-day',
    name: 'Open Day',
    components: {
      default: () => import(/* webpackChunkName: "open-day" */ '@/views/Events/OpenDay.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  // {
  //   path: '/class/open-day/:slug',
  //   name: 'Self-service Open Day',
  //   components: {
  //     default: () => import(/* webpackChunkName: "self-service-open-day" */ '@/views/Self-service/OpenDay.vue')
  //   },
  //   beforeEnter: (to, from, next) => {
  //     prerender(to, from, next)
  //   }
  // },
  {
    path: '/join-event',
    name: 'Join Event',
    components: {
      default: () => import(/* webpackChunkName: "events" */ '@/views/Events/JoinEvent.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/short-course',
    name: 'Short Course',
    components: {
      default: () => import(/* webpackChunkName: "short-course" */ '@/views/ShortCourse/ShortCourse.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  // {
  //   path: '/redhat',
  //   name: 'Redhat',
  //   components: {
  //     default: () => import(/* webpackChunkName: "redhat" */ '@/views/ShortCourse/Redhat.vue')
  //   }
  // },
  {
    path: '/partner-profile',
    name: 'Partner',
    component: () => import(/* webpackChunkName: "partner-profile" */ '@/views/Partner/Partner.vue'),
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/prakerja',
    name: 'Prakerja',
    components: {
      default: () => import(/* webpackChunkName: "prakerja" */ '@/views/Prakerja/Prakerja.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/prakerja/panduan',
    name: 'Panduan Redeem Prakerja',
    components: {
      default: () => import(/* webpackChunkName: "prakerja" */ '@/views/Static/HowToRedeem.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/prakerja/redeem/failed',
    name: 'PrakerjaRedeemFailed',
    meta: {
      login: true
    },
    component: () => import(/* webpackChunkName: "redeem" */ '@/views/Redeem/RedeemFailed.vue'),
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/help',
    name: 'PrakerjaRedeemContact',
    meta: {
      login: false,
      hideWa: true
    },
    component: () => import(/* webpackChunkName: "redeem" */ '@/views/Redeem/RedeemContact.vue'),
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/prakerja/redeem/:slugLp?/:slugDp?',
    name: 'PrakerjaRedeemV3',
    meta: {
      login: true
    },
    component: () => import(/* webpackChunkName: "redeem" */ '@/views/Redeem/RedeemV3.vue'),
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/prakerja/:referralcode',
    name: 'prakerja-referral',
    components: {
      default: () => import(/* webpackChunkName: "referralCode" */ '@/views/Prakerja/Prakerja.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/technologeek',
    name: 'Podcasts',
    components: {
      default: () => import(/* webpackChunkName: "podcasts" */ '@/views/Static/Podcast.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/products',
    name: 'Products',
    components: {
      default: () => import(/* webpackChunkName: "products" */ '@/views/Products/Products.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/products/lms',
    name: 'G2 LMS',
    components: {
      default: () => import(/* webpackChunkName: "products-lms" */ '@/views/Products/G2LMS.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/products/talent',
    name: 'G2 Talent',
    components: {
      default: () => import(/* webpackChunkName: "products-talent" */ '@/views/Products/G2Talent.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/thankyou/:class',
    name: 'Thankyou',
    components: {
      default: () => import(/* webpackChunkName: "thankyou" */ '@/views/ThankYou/ThankYou.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/profile-student',
    name: 'Profile',
    redirect: '/user/my-classes',
    meta: {
      login: true
    },
    components: {
      default: () => import(/* webpackChunkName: "profile-student" */ '@/views/Student/ProfileStudent.vue')
    }
  },
  {
    path: '/dashboard/:id',
    name: 'Dashboard Student',
    meta: {
      login: true,
      page: 'dashboard'
    },
    components: {
      default: () => import(/* webpackChunkName: "dashboard-student" */ '@/views/Student/Dashboard.vue')
    }
  },
  {
    path: '/administration/:registrationId',
    name: 'Administration Student',
    meta: {
      login: true,
      page: 'dashboard'
    },
    components: {
      default: () => import(/* webpackChunkName: "administration" */ '@/views/Student/AdministrationPage.vue')
    }
  },
  {
    path: '/dashboard/:id/message/:messageTopicId',
    name: 'Message Detail',
    meta: {
      login: true,
      page: 'dashboard'
    },
    components: {
      default: () => import(/* webpackChunkName: "dashboard-student" */ '@/views/Student/Dashboard.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/dashboard/:id/quiz',
    name: 'Quiz',
    meta: {
      login: true,
      page: 'dashboard'
    },
    components: {
      default: () => import(/* webpackChunkName: "quiz" */ '@/views/Student/Quiz.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/class/:category/:slug',
    name: 'Program Detail',
    components: {
      default: () => import(/* webpackChunkName: "program-detail" */ '@/views/Programs/ProgramDetail.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  // {
  //   path: '/class/collaboration/:category/:slug',
  //   name: 'Collaboration',
  //   components: {
  //     default: () => import(/* webpackChunkName: "collaboration" */ '@/views/Collaboration/Collaboration.vue')
  //   },
  //   beforeEnter: (to, from, next) => {
  //     prerender(to, from, next)
  //   }
  // },
  {
    path: '/event',
    name: 'Event',
    components: {
      default: () => import(/* webpackChunkName: "event-page" */ '@/views/Events/Events.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/event/:slug',
    name: 'Event Details',
    components: {
      default: () => import(/* webpackChunkName: "event-detail" */ '@/views/Events/EventDetails.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/event/:slug/thank-you',
    name: 'Thank You Page Event',
    components: {
      default: () => import(/* webpackChunkName: "thank-you-event" */ '@/views/ThankYou/ThankYouRegistEvent.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/order/thankyou',
    name: 'Thank You Order Page',
    components: {
      default: () => import(/* webpackChunkName: "thank-you-order" */ '@/views/ThankYou/ThankYouOrder.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/my-class',
    name: 'My Class',
    meta: {
      login: true
    },
    components: {
      default: () => import(/* webpackChunkName: "my-class" */ '@/views/Prakerja/MyClass.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/my-class/:id',
    name: 'My Class Detail',
    meta: {
      login: true,
      page: 'dashboard'
    },
    components: {
      default: () => import(/* webpackChunkName: "my-class-detail" */ '@/views/Prakerja/MyClassDetail.vue')
    }
  },
  {
    path: '/my-class/:id/:examType',
    name: 'my-class-exam-page',
    meta: {
      login: true,
      page: 'dashboard',
      hideWa: true
    },
    components: {
      default: () => import(/* webpackChunkName: "my-class-detail-exam" */ '@/views/Prakerja/PrakerjaTestForm.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/my-class/:id/:examType/reference',
    name: 'my-class-exam-page-reference',
    components: {
      default: () => import(/* webpackChunkName: "my-class-detail-reference" */ '@/views/Prakerja/PrakerjaTestForm.vue')
    },
    meta: {
      login: true,
      page: 'dashboard',
      hideWa: true
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/student/class/:id/:classType',
    name: 'non-webinar-class',
    meta: {
      login: true,
      page: 'dashboard'
    },
    components: {
      default: () => import(/* webpackChunkName: "class-detail" */ '@/views/Prakerja/NonWebinarDetail.vue')
    }
  },
  {
    path: '/student/class/:id/exercise/:exerciseId',
    name: 'exercise',
    components: {
      default: () => import(/* webpackChunkName: "my-class-detail-exercise" */ '@/views/Prakerja/PrakerjaTestForm.vue')
    },
    meta: {
      login: true,
      page: 'dashboard',
      new: true,
      hideWa: true
    }
  },
  {
    path: '/student/class/:id/exercise/:exerciseId/reference',
    name: 'exercise reference',
    components: {
      default: () => import(/* webpackChunkName: "my-class-detail-reference" */ '@/views/Prakerja/PrakerjaTestForm.vue')
    },
    meta: {
      login: true,
      page: 'dashboard',
      new: true,
      hideWa: true
    }
  },
  {
    path: '/my-referral-code',
    name: 'my-referral-code-page',
    components: {
      default: () => import(/* webpackChunkName: "my-class-detail-referral" */ '@/views/Prakerja/MyReferralCode.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/user',
    name: 'User',
    redirect: '/user/profile',
    components: {
      default: () => import(/* webpackChunkName: "UserLayout" */ '@/views/User/UserLayout.vue')
    },
    meta: {
      login: true
    },
    children: [
      {
        path: 'profile',
        name: 'User Profile',
        meta: {
          login: true
        },
        components: {
          default: () => import(/* webpackChunkName: "profile" */ '@/views/User/Profile.vue')
        }
      },
      {
        path: 'my-classes',
        name: 'User Classes',
        meta: {
          login: true
        },
        components: {
          default: () => import(/* webpackChunkName: "UserClasses" */ '@/views/User/UserClasses.vue')
        }
      },
      {
        path: 'change-password',
        name: 'Change Password',
        meta: {
          login: true
        },
        components: {
          default: () => import(/* webpackChunkName: "change-password-modal" */ '@/components/student/ChangePasswordModal')
        }
      },
      {
        path: 'account',
        name: 'User Account',
        meta: {
          login: true
        },
        components: {
          default: () => import(/* webpackChunkName: "UserAccount" */ '@/views/User/UserAccount')
        }
      }
    ]
  },
  {
    path: '/contact',
    name: 'Contact Us',
    components: {
      default: () => import(/* webpackChunkName: "contact" */ '@/views/Contact/Contact.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/clouducation',
    name: 'Clouducation',
    components: {
      default: () => import(/* webpackChunkName: "clouducation" */ '@/views/Microsite/Clouducation.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/clouducation/thankyou',
    name: 'Clouducation Thank You',
    components: {
      default: () => import(/* webpackChunkName: "clouducation-thankyou" */ '@/views/Microsite/ClouducationThankYou.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/thankyou/register',
    name: 'Register Event Thank You',
    components: {
      default: () => import(/* webpackChunkName: "register-event" */ '@/views/ThankYou/ThankYouRegistEvent.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    components: {
      default: () => import(/* webpackChunkName: "privacy-policy" */ '@/views/Static/PrivacyPolicy.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/terms-conditions',
    name: 'Terms and Conditions',
    components: {
      default: () => import(/* webpackChunkName: "terms-conditions" */ '@/views/Static/TermsConditions.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/thank-for-registration',
    name: 'Thank You Regist',
    components: {
      default: () => import(/* webpackChunkName: "thank-for-registration" */ '@/views/ThankYou/ThankYouRegist.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/career',
    name: 'Career Page',
    components: {
      default: () => import(/* webpackChunkName: "CareerPage" */ '@/views/Career/CareerPage.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/career/:position/:id',
    name: 'Career Detail Page',
    components: {
      default: () => import(/* webpackChunkName: "CareerDetailPage" */ '@/views/Career/Detail/DetailPage.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/attendance',
    name: 'Attendance',
    components: {
      default: () => import(/* webpackChunkName: "Attendance" */ '@/views/Attendance/Attendance.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/talent-pipeline',
    name: 'Talent Pipeline',
    components: {
      default: () => import(/* webpackChunkName: "Talent" */ '@/views/Static/Talent.vue')
    },
    beforeEnter: (to, from, next) => {
      prerender(to, from, next)
    }
  },
  {
    path: '/thankyou-for-scholarship',
    name: 'Register Scholarship',
    components: {
      default: () => import(/* webpackChunkName: "ThankYouScholarship" */ '@/views/ThankYou/ThankYouScholarship.vue')
    }
  },
  {
    path: '/career-counseling',
    name: 'Career Coaching',
    components: {
      default: () => import(/* webpackChunkName: "CareerCoaching" */ '@/views/Career/CareerCoaching.vue')
    }
  },
  {
    path: '/installment',
    name: 'Installment',
    components: {
      default: () => import(/* webpackChunkName: "Installment" */ '@/views/Loan/Installment/Installment.vue')
    }
  },
  {
    path: '/business',
    name: 'Business',
    components: {
      default: () => import(/* webpackChunkName: "Business" */ '@/views/Static/Business.vue')
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (!to.hash) {
      return { x: 0, y: 0 }
    }
  }
})

const parseJwt = (token) => {
  var base64Url = token.split('.')[1]
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

router.beforeEach((to, from, next) => {
  if (from.name === to.name) {
    prerender(to, from, next)
  }
  if (to.path === '/class/bootcamp/data-engineer') {
    next({
      path: '/class/bootcamp/data-engineering'
    })
  }
  store.dispatch('auth/getCurrentUser')
  if (store.getters['auth/userData'].token) {
    const tokenExpiredAt = parseJwt(store.getters['auth/userData'].token).exp
    const isAlreadyExpired = new Date().getTime() > new Date(tokenExpiredAt * 1000).getTime()
    if (isAlreadyExpired && to.meta.login) {
      store.dispatch('auth/showTokenExpiredModal')
      return
    } else {
      next()
    }
  }
  if (store.getters['common/isNavOpen']) {
    store.dispatch('common/toggleSideBar')
    next()
  }
  if (store.getters['auth/isLoggedIn'] && to.name === 'Login') {
    next({
      name: 'Home'
    })
    return
  }
  if (store.getters['auth/isLoggedIn'] && to.name === 'Register') {
    next({
      name: 'Home'
    })
    return
  }
  if (!store.getters['auth/isLoggedIn'] && to.name === 'my-referral-code-page') {
    next({
      name: 'Home'
    })
    store.dispatch('prakerja/goMyReferralFromHome', { access: true })
    return
  }
  if (!store.getters['auth/isLoggedIn'] && to.meta.login) {
    next({
      name: 'Login',
      query: { ref: to.fullPath }
    })
    return
  }
  if (to.name === 'Clouducation') {
    store.dispatch('auth/getUserEvent')
    next()
  }
  if (to.query.utm_campaign || from.query.utm_campaign) {
    sessionStorage.setItem('utm_campaign', to.query.utm_campaign ? to.query.utm_campaign : from.query.utm_campaign)
  }
  if (to.query.utm_medium || from.query.utm_medium) {
    sessionStorage.setItem('utm_medium', to.query.utm_medium ? to.query.utm_medium : from.query.utm_medium)
  }
  if (to.query.utm_source || from.query.utm_source) {
    sessionStorage.setItem('utm_source', to.query.utm_source ? to.query.utm_source : from.query.utm_source)
  }

  next()
})

router.afterEach((to, from) => {
  if (to.params.slug === undefined) {
    if (to.name === 'Prakerja') {
      store.dispatch('common/changeSlug', { slug: 'prakerja' })
    } else {
      store.dispatch('common/changeSlug', { slug: 'general' })
    }
  } else {
    store.dispatch('common/changeSlug', { slug: to.params.slug })
  }
})

export default router
