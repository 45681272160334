import api from '@/api'
const state = {
  cartId: '',
  cartItem: 0
}
const actions = {
  getCartList: ({ commit }, { userId }) => {
    return new Promise((resolve, reject) => {
      api.marketplace.getCartList(userId).then(
        (response) => {
          commit('setCartId', response.data.data.id)
          commit('setCartItem', response.data.data.totalItem)
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  deleteCartItem({ dispatch }, { cartId, cartItemId }) {
    return new Promise((resolve, reject) => {
      api.marketplace.deleteCartItem(cartId, cartItemId).then(
        (response) => {
          dispatch('getCartList', { userId: localStorage.getItem('user_id') })
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  addCartItem({ dispatch }, { requestBody }) {
    return new Promise((resolve, reject) => {
      api.marketplace.addCartItem(requestBody).then(
        (response) => {
          dispatch('getCartList', { userId: localStorage.getItem('user_id') })
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  findAll({ _ }, { params }) {
    return new Promise((resolve, reject) => {
      api.marketplace.findAll(params).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },

  createOrder({ _ }, { payload }) {
    return new Promise((resolve, reject) => {
      api.marketplace.createOrder(payload).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  createTransactions({ _ }, { orderId }) {
    return new Promise((resolve, reject) => {
      api.marketplace.createTransactions(orderId).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  checkAndUpdatePayment({ _ }, { orderId }) {
    return new Promise((resolve, reject) => {
      api.marketplace.checkAndUpdatePayment(orderId).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getOrderByUserId({ _ }, { userId, params }) {
    return new Promise((resolve, reject) => {
      api.marketplace.getOrderByUserId(userId, params).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getOrderByOrderId({ _ }, { orderId }) {
    return new Promise((resolve, reject) => {
      api.marketplace.getOrderByOrderId(orderId).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
const mutations = {
  setCartId(state, value) {
    state.cartId = value
  },
  setCartItem(state, value) {
    state.cartItem = value
  }
}
const getters = {
  cartId: (state) => state.cartId,
  cartItem: (state) => state.cartItem
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
