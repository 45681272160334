import api from '@/api'
const state = {
  educationalInstitutions: [],
  digitalPlatforms: [],
  emailEvent: ''
}
const actions = {
  setEmailEvent: ({ commit }, { value }) => {
    commit('setEmailEvent', value)
  },
  getEducationalInstitutions({ commit }, { slug }) {
    return new Promise((resolve, reject) => {
      api.data.educationalInstitutions(slug).then(
        (response) => {
          const sortByCode = response.data.data.sort((a, b) => parseInt(a.code) - parseInt(b.code))
          commit('setEducationalInstitutions', sortByCode)
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getDigitalPlatforms({ commit }, { slug }) {
    return new Promise((resolve, reject) => {
      api.data.digitalPlatforms(slug).then(
        (response) => {
          const pickSkillAcademy = response.data.data.filter((dp) => dp.code === 'Skill Academy')[0]
          const reorderDp = response.data.data.filter((dp) => dp.code !== 'Skill Academy')
          if (pickSkillAcademy) reorderDp.push(pickSkillAcademy)
          commit('setDigitalPlatforms', reorderDp)
          resolve(response.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
const mutations = {
  setEmailEvent(state, value) {
    state.emailEvent = value
  },
  setEducationalInstitutions(state, value) {
    state.educationalInstitutions = value
  },
  setDigitalPlatforms(state, value) {
    state.digitalPlatforms = value
  }
}
const getters = {
  educationalInstitutions: (state) => state.educationalInstitutions,
  digitalPlatforms: (state) => state.digitalPlatforms,
  emailEvent: (state) => state.emailEvent
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
