import { api } from '@/constant'
import baseApi from '@/service/api-general'

const contact = {
  contactUs: (payloads) => {
    const url = api.contact.default
    return baseApi.post(url, payloads)
  },
  contactUsWithCategory: (payloads) => {
    const url = api.contact.withCategory
    return baseApi.post(url, payloads)
  }
}
export default contact
