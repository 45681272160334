import axios from 'axios'
import home from './home'

const mockRoutes = [...home]
let mockingEnabled = false

const mocks = {}

export function addMock() {
  mockRoutes.forEach((item) => {
    const url = `${item.method}${item.url}`
    mocks[url] = item.response
  })
}

export function enableMocking(state) {
  mockingEnabled = state
}

const isUrlMocked = (url) => url in mocks

const getMockError = (config) => {
  const mockError = new Error()
  const url = `${config.method.toUpperCase()}${config.url}`
  mockError.mockData = mocks[url]
  mockError.config = config
  return Promise.reject(mockError)
}

const isMockError = (error) => Boolean(error.mockData)

const getMockResponse = (mockError) => {
  const { mockData, config } = mockError
  // Handle mocked error (any non-2xx status code)
  if (mockData.status && String(mockData.status)[0] !== '2') {
    const err = new Error(mockData.message || 'mock error')
    err.code = mockData.status
    return Promise.reject(err)
  }
  // Handle mocked success
  return Promise.resolve({
    data: {},
    status: 200,
    statusText: 'OK',
    headers: {},
    config,
    isMock: true,
    ...mockData
  })
}

export function requestHandler(config) {
  const url = `${config.method.toUpperCase()}${config.url}`
  if (mockingEnabled && isUrlMocked(url)) {
    console.log('axios mocking ' + `${config.method.toUpperCase()}${config.url}`)
    return getMockError(config)
  }
  return config
}

export function errorHandlerMock(error) {
  if (isMockError(error)) {
    return getMockResponse(error)
  }
  return Promise.reject(error)
}
// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const url = `${config.method.toUpperCase()}${config.url}`
    if (mockingEnabled && isUrlMocked(url)) {
      console.log('axios mocking ' + `${config.method.toUpperCase()}${config.url}`)
      return getMockError(config)
    }
    return config
  },
  (error) => Promise.reject(error)
)

// Add a response interceptor
axios.interceptors.response.use(
  (response) => response,
  (error) => errorHandlerMock(error)
)
