import { api } from '@/constant'
import { getAuthHeader, serializeQueryParams } from '@/utils'
import apiV1 from '@/service/api-v1'
import apiV2 from '@/service/api-v2'

const marketplace = {
  getCartList: (userId) => {
    const url = api.marketplace.getCartList(userId)
    return apiV1.get(url, {
      headers: getAuthHeader()
    })
  },

  deleteCartItem: (cartId, cartItemId) => {
    const url = api.marketplace.deleteCartItem(cartId, cartItemId)
    return apiV1.delete(url, {
      headers: getAuthHeader()
    })
  },
  createOrder: (payload) => {
    const url = api.marketplace.createOrder
    return apiV1.post(url, payload, {
      headers: getAuthHeader()
    })
  },
  createTransactions: (orderId) => {
    const url = api.marketplace.createTransactions(orderId)
    return apiV1.post(
      url,
      {},
      {
        headers: getAuthHeader()
      }
    )
  },
  checkAndUpdatePayment: (orderId) => {
    const url = api.marketplace.checkAndUpdatePayment(orderId)
    return apiV1.post(
      url,
      {},
      {
        headers: getAuthHeader()
      }
    )
  },
  getOrderByUserId: (userId, params) => {
    const url = api.marketplace.getOrderByUserId(userId) + serializeQueryParams(params)
    return apiV1.get(url, {
      headers: getAuthHeader()
    })
  },
  getOrderByOrderId: (orderId) => {
    const url = api.marketplace.getOrderByOrderId(orderId)
    return apiV1.get(url, {
      headers: getAuthHeader()
    })
  },
  addCartItem: (requestBody) => {
    const url = api.marketplace.addCartItem
    return apiV1.post(url, requestBody, { headers: getAuthHeader() })
  },
  findAll: (params) => {
    const url = api.marketplace.findAll + serializeQueryParams(params)
    return apiV2.get(url)
  }
}
export default marketplace
