import api from '@/api'
const state = {
  seoContent: {},
  seoList: []
}
const actions = {
  getSeoContent({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      api.seo.seoContent(params).then(
        (response) => {
          commit('setSeoContent', response.data.data)
          resolve(response.data)
        },
        (error) => reject(error)
      )
    })
  },
  getSeoList({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      api.seo.seoList(params).then(
        (response) => {
          commit('setSeoList', response.data.data)
          resolve(response.data)
        },
        (error) => reject(error)
      )
    })
  }
}
const mutations = {
  setSeoContent(state, value) {
    state.seoContent = value
  },
  setSeoList(state, value) {
    state.seoList = value
  }
}
const getters = {
  seoContent: (state) => state.seoContent,
  seoList: (state) => state.seoList
}
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
