const convertLinkToFileName = (link, isShorter) => {
  let linkToName = link.substring(63, link.length)
  if (linkToName.length >= 100 && isShorter) {
    linkToName = `${linkToName.substring(0, 40)}.....${linkToName.substring(linkToName.length - 40, linkToName.length)}`
  }
  return linkToName.replace(/_/g, ' ')
}

const nameToPath = name => {
  return name.replace(/\s+/g, '-').toLowerCase()
}

const pathToName = link => {
  return link.replace(/-/g, ' ').replace(/(?:^|\s)\S/g, function (firstChar) {
    return firstChar.toUpperCase()
  })
}

export { convertLinkToFileName, nameToPath, pathToName }
