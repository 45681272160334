import Vue from 'vue'
import Vuex from 'vuex'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './translations/i18n'
import 'tailwindcss/tailwind.css'
import LazyLoadDirective from './directives/LazyLoadDirective'
import dayjs from 'dayjs'
import VueSocialSharing from 'vue-social-sharing'
import VueMeta from 'vue-meta'

axios.defaults.baseURL = process.env.VUE_APP_BASE_PATH

Vue.use(VueI18n)
Vue.use(Vuex)

Vue.config.productionTip = false
Vue.directive('lazyload', LazyLoadDirective)
Vue.use(VueSocialSharing)
Vue.use(VueMeta)

Vue.prototype.dayjs = dayjs

const app = new Vue({
  i18n,
  store,
  router,
  render: (h) => h(App),
  mounted() {
    document.dispatchEvent(new Event('render-event'))
  }
})
document.addEventListener('DOMContentLoaded', function () {
  app.$mount('#app')
})
