import api from '@/api'

const state = {
  programDetail: {},
  programWebDetail: {},
  schedulePrice: [],
  programCategoryList: [],
  programByCategory: [],
  variantDetail: {}
}
const actions = {
  getProgramBySlug({ commit }, { slug }) {
    return new Promise((resolve, reject) => {
      api.program.getProgramBySlug(slug).then(
        (response) => {
          commit('setProgramDetail', response.data.data)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getProgramByWebSlug({ commit }, { slug, params }) {
    return new Promise((resolve, reject) => {
      api.program.getProgramByWebSlug(slug, params).then(
        (response) => {
          commit('setProgramWebDetail', response.data.data)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getVariantDetail({ commit }, { variantId, params }) {
    return new Promise((resolve, reject) => {
      api.program.getVariantDetail(variantId, params).then(
        (response) => {
          commit('setVariantDetail', response.data.data)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getMultipleProgramBySlug({ _ }, { slug }) {
    return new Promise((resolve, reject) => {
      api.program.getProgramBySlug(slug).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  downloadSyllabus({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.program.downloadSyllabus(payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getLmsClasses({ _ }, { coreClassId, params }) {
    return new Promise((resolve, reject) => {
      api.program.getLmsClasses(coreClassId, params).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getEventList({ _ }, { params }) {
    return new Promise((resolve, reject) => {
      api.program.getEventList(params).then(
        (response) => {
          resolve(response.data.data)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getFormToken({ _ }) {
    return new Promise((resolve, reject) => {
      api.program.getFormToken().then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  attendOpenDay({ _ }, { eventId, payloads }) {
    return new Promise((resolve, reject) => {
      api.program.attendOpenDay(eventId, payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  enroll({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.program.enroll(payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  enrollV2({ _ }, { payloads }) {
    return new Promise((resolve, reject) => {
      api.program.enrollV2(payloads).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  newEnroll({ _ }, { payloads, formToken }) {
    return new Promise((resolve, reject) => {
      api.program.newEnroll(payloads, formToken).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  enrollBookSession({ _ }, { payloads, formToken }) {
    return new Promise((resolve, reject) => {
      api.program.enrollBookSession(payloads, formToken).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  newEnrollV2({ _ }, { payloads, formToken }) {
    return new Promise((resolve, reject) => {
      api.program.newEnrollV2(payloads, formToken).then(
        (response) => {
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getSchedulePrice({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      api.program.getSchedulePrice(params).then(
        (response) => {
          commit('setSchedulePrice', response.data.data)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getProgramCategoryList({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      api.program.getProgramCategoryList(params).then(
        (response) => {
          commit('setProgramCategoryList', response.data.data.children)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  },
  getProgramByCategory({ commit }, { params }) {
    return new Promise((resolve, reject) => {
      api.program.getProgramByCategory(params).then(
        (response) => {
          commit('setProgramByCategory', response.data.data)
          resolve(response)
        },
        (error) => {
          reject(error)
        }
      )
    })
  }
}
const mutations = {
  setProgramDetail(state, value) {
    state.programDetail = value
  },
  setProgramWebDetail(state, value) {
    state.programWebDetail = value
  },
  setSchedulePrice(state, value) {
    state.schedulePrice = value
  },
  setProgramCategoryList(state, value) {
    state.programCategoryList = value
  },
  setProgramByCategory(state, value) {
    state.programByCategory = value
  },
  setVariantDetail(state, value) {
    state.variantDetail = value
  }
}
const getters = {
  programDetail: (state) => state.programDetail,
  programWebDetail: (state) => state.programWebDetail,
  schedulePrice: (state) => state.schedulePrice,
  programCategoryList: (state) => state.programCategoryList,
  programByCategory: (state) => state.programByCategory,
  variantDetail: (state) => state.variantDetail
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
