const HOMEPAGEID = '2c9040e6761cccce01761cdc91390000'
const SCHOLARSHIPID = '2c9f939c7673f50f017673fa0bae0005'
const PRAKERJAID = '2c92856e7626a115017626ca4b6c0000'
const api = {
  base: process.env.VUE_APP_BASE_PATH,
  homepage: {
    cmsContent: `/homepage/${HOMEPAGEID}`,
    popUp: '/new/homepage/popup',
    program: (programName) => `/homepage/cms/programs/${programName}`
  },
  seo: {
    content: '/seo-page',
    list: '/cms/seo-management'
  },
  auth: {
    loginStudent: '/auth/login',
    loginPrakerja: '/auth/login',
    loginMitra: '/mitra/login',
    logoutPrakerja: '/account/logout',
    resetPasswordCRM: '/auth/reset-password',
    resetPasswordPrakerja: '/auth/reset-password',
    registerEvent: '/class-enrollments',
    registerMitra: '/mitra/signup',
    registerStudent: '/student/auth/register/noclass',
    registerPrakerja: '/student',
    registerOpenDay: (openDayId) => `/open-days/${openDayId}/participants`,
    changePasswordCRM: '/user/change-password',
    changePasswordPrakerja: '/user/change-password',
    loginEvent: '/user-access-event/login',
    resetPasswordEvent: '/user-access-event/reset-password'
  },
  program: {
    all: '/class',
    programBySlug: (slug) => `/class/slug/${slug}`,
    variantDetail: (variantId) => `/web-class/variants/${variantId}`,
    programByWebSlug: (slug, params) => `/web-class/slug/${slug}${params}`,
    downloadSyllabus: '/syllabus/download',
    webClass: '/web-class',
    attendOpenDay: (eventId) => `/open-days/${eventId}/attendances`,
    formToken: '/form-tokens',
    enroll: '/student/enroll',
    enrollV2: '/public/form-submissions',
    newEnroll: '/class-enrollments',
    newEnrollV2: '/class-enrollments',
    schedulePrice: '/schedule-prices/active',
    eventList: '/web-events',
    programCategoryList: '/new/homepage/category-tree',
    storeClasses: '/new/homepage/store-classes'
  },
  testimony: {
    default: '/testimonies',
    all: '/testimonies/all'
  },
  scholarship: {
    default: `/beasiswa/${SCHOLARSHIPID}`
  },
  partner: {
    getAllAgreement: '/agreement/get-all',
    getPartnerDetail(id) {
      return `/mitra/get/${id}`
    }
  },
  instructor: {
    instructorById(id) {
      return `/experts/${id}`
    }
  },
  prakerja: {
    content: `/prakerja/${PRAKERJAID}`,
    profile: '/student/my-profile',
    redeem: '/vouchers/redeem',
    absensi(classId) {
      return `/student/my-class/${classId}/attendances`
    },
    absensiOffline(studentLmsClassId, activityItemId) {
      return `/student/my-class/${studentLmsClassId}/offlines/${activityItemId}`
    },
    absensiWebinar(studentLmsClassId, activityItemId) {
      return `/student/my-class/${studentLmsClassId}/webinars/${activityItemId}`
    },
    myClass: '/student/my-class',
    myClassDetail(classId) {
      return '/student/my-class/' + classId
    },
    myClassAdditionalInfo(studentLmsClassId) {
      return `/student/my-class/${studentLmsClassId}/additional-info`
    },
    videoStatus(studentLmsClassId, activityItemId) {
      return `/student/my-class/${studentLmsClassId}/videos/${activityItemId}`
    },
    documentStatus(studentLmsClassId, activityItemId) {
      return `/student/my-class/${studentLmsClassId}/documents/${activityItemId}`
    },
    linkStatus(studentLmsClassId, activityItemId) {
      return `/student/my-class/${studentLmsClassId}/links/${activityItemId}`
    },
    recurringStatus(studentLmsClassId, activityItemId) {
      return `/student/my-class/${studentLmsClassId}/recurrings/${activityItemId}`
    },
    dropboxQuestion(classId) {
      return `/student/my-class/${classId}/discussion`
    },
    examData(classId, examType) {
      return `/student/my-class/${classId}/exercises/${examType}`
    },
    examDataNonWebinar(studentLmsClassId, activityItemId) {
      return `/student/my-class/${studentLmsClassId}/exercises/${activityItemId}`
    },
    examDataReference(classId, examType) {
      return `/student/my-class/${classId}/exercises/${examType}/reference`
    },
    examDataNonWebinarReference(studentLmsClassId, activityItemId) {
      return `/student/my-class/${studentLmsClassId}/exercises/${activityItemId}/result`
    },
    needRedemptionCode(studentLmsClassId, activityItemId) {
      return `/student/my-class/${studentLmsClassId}/exercises/${activityItemId}/check`
    },
    uploadTugas(classId) {
      return `/student/my-class/${classId}/exercises/tugas`
    },
    uploadTugasNonWebinar(studentLmsClassId, activityItemId) {
      return `/student/my-class/${studentLmsClassId}/tasks/${activityItemId}`
    },
    myReferral: '/account/myreferral',
    myReferralList: '/account/myreferral/list'
  },
  student: {
    redeemRegister: 'vouchers/redeem-register',
    // leaveRequest: '/new/hr/leave',
    leaveRequest: '/leave',
    // leaveBalance: '/new/hr/leave-balance',
    leaveBalance: '/leave-balance',
    getClassById(classId) {
      return `/student/lms-class/${classId}`
    },
    getSchedule(params) {
      return `/lms-class/availability${params}`
    },
    getScheduleDashboard(classId, params) {
      return `/student/${classId}/schedules${params}`
    },
    getScheduleOnward(classId) {
      return `/student/${classId}/schedules/onward`
    },
    getStudentDetail(studentId) {
      return `/students-external/${studentId}`
    },
    updateStudent(studentId) {
      return `/students-external/updatestudent/${studentId}`
    },
    updateEmailStudent(studentId) {
      return `/students-external/editemailstudent/${studentId}`
    },
    getMyClass(params) {
      return `/class${params}`
    },
    updateTheTask(submissionId) {
      return `/lms-class/update-tugas/${submissionId}`
    },
    updateTheExam(submissionId) {
      return `/lms-class/update-ujian/${submissionId}`
    },
    getMessageList(classId, params) {
      return `/lms-class/${classId}/message-topics${params}`
    },
    replyMessage(messageTopicId) {
      return `/message-topics/${messageTopicId}/messages`
    },
    doTheTask: '/lms-class/create-upload-tugas',
    doTheExam: '/lms-class/create-upload-ujian',
    createAttendance: '/student/attendance',
    image: '/image',
    file: '/files',
    administration: '/student/my-registration'
  },
  marketplace: {
    addCartItem: '/cart',
    getCartList(userId) {
      return `/cart/user/${userId}`
    },
    deleteCartItem(cartId, cartItemId) {
      return `/cart/${cartId}/item/${cartItemId}`
    },
    createTransactions(orderId) {
      return `/payment/order/${orderId}`
    },
    checkAndUpdatePayment(orderId) {
      return `payment/order/${orderId}/status`
    },
    getOrderByOrderId(orderId) {
      return `/order/${orderId}`
    },
    getOrderByUserId(userId) {
      return `/order/user/${userId}`
    },
    createOrder: '/order',
    findAll: '/marketplace'
  },
  contact: {
    default: '/contact-us/g2academy.co',
    withCategory: '/v2/contact-us'
  },
  data: {
    getUploadUrl(params) {
      return `/uploads${params}`
    },
    educationalInstitutions: '/educational-institutions',
    digitalPlatforms: '/digital-platforms'
  },
  career: {
    vacancies: '/tis/partners/career-page/vacancies',
    vacancyDetail: (vacancyId) => `/tis/partners/career/${vacancyId}`,
    applyJob: (vacancyId) => `/jobs/${vacancyId}/apply`,
    regencies: '/regencies'
  },
  attend: {
    // attendance: '/new/hr/attendances'
    attendance: '/attendances'
  }
}

export default api
